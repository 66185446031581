export type PostDetailComponentProps = {
  history?: any;
  postTitle?: string;
  organisation?: string;
  description?: string;
  datePosted?: string;
  contactName?: string;
  contactEmail?: string;
  contactNumber?: string;
  contactLink?: string;
  icon?: any;
  isPublished?:boolean;
};

const PostDetailComponent = (props: PostDetailComponentProps) => { 

  return (
    <>
      <h2 className="post-title">{props.postTitle}</h2>
      <div className="opportunity-type-icon-container">
        <img src={props.icon} alt="Show logo that represents the page" />
      </div>
      <h3 className="organisation-name">{props.organisation}</h3>
      <p className="preview-form-title">Description</p>
      <p className="preview-form-input">{props.description}</p>
      <p className="preview-form-title">Date</p>
      <p className="preview-form-input">
        {props.datePosted}
      </p>

      <p className="preview-form-title">Contact Name</p>
      <p className="preview-form-input">{props.contactName}</p>

      <p className="preview-form-title">Contact Email</p>
      <p className="preview-form-input">{props.contactEmail}</p>

      <p className="preview-form-title">Contact Number</p>
      <p className="preview-form-input">{props.contactNumber}</p>

      <p className="preview-form-title">Link</p>
      <p className="preview-form-input">{props.contactLink}</p>
    </>
  );
}

export default PostDetailComponent;