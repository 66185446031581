import CustomButton from "../../components/custom-button/custom-button.component";

import { ReactComponent as Warning } from "../../../assets/error-confirmation.svg";
import { ReactComponent as Success } from "../../../assets/success-confirmation.svg";

import Backward from "../../../assets/back-red.png";
import BackwardBlue from "../../../assets/back-blue.png";
import Confirm from "../../../assets/confirm_.png";
import Close from "../../../assets/close_.png";

import "./custom-prompt.component.style.scss";

type CustomPromptComponentProps = {
  closeHandler?:any,
  onClick?:any,
  confirmLabelButton?:string,
  cancelLabelButton?:string,
  isWarning?:boolean,
  promptMessage?:string
}

const CustomPromptComponent = (props:CustomPromptComponentProps) => {
  return (
    <>
      <div className="confirmation-background" />
      <div className="confirmation-container">
        <div className="confirmation-image-container">

          {props.isWarning && (
            <Warning />
          )}
          {!props.isWarning && (
            <Success />
          )}

        </div>

        {props.isWarning && (
          <h2 className="confirmation-error-heading">
            {props.promptMessage}
          </h2>
        )}
        {!props.isWarning && (
          <h2 className="confirmation-heading">
            {props.promptMessage}
          </h2>
        )}

        <div className="confirmation-button-container">
          {props.isWarning && (
            <>
              <CustomButton 
                            butonStyle="warning-navigation" 
                            iconLeft={true} 
                            icon={Backward}
                            onClick={() => props.closeHandler()}
                            style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}
                          >
                          { props.cancelLabelButton }
              </CustomButton>
              <CustomButton 
                            butonStyle="warning"
                            iconLeft={true} 
                            icon={Close}
                            onClick={() => props.onClick()}
                            style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}
                          >
                          {props.confirmLabelButton}
              </CustomButton>            
            </>  
          )}

          {!props.isWarning && (
            <>
              <CustomButton 
                            butonStyle="success-navigation" 
                            iconLeft={true} 
                            icon={BackwardBlue}
                            onClick={() => props.closeHandler()}
                            style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}
                          >
                          { props.cancelLabelButton }
              </CustomButton>
              <CustomButton 
                            butonStyle="action"
                            iconLeft={true} 
                            icon={Confirm}
                            onClick={() => props.onClick()}
                            style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}
                          >
                          {props.confirmLabelButton}
              </CustomButton>
            </>  
          )}

        </div>
      </div>
    </>
  );
}

export default CustomPromptComponent;