class PostDetailHelper {
  
  generateCustomArray = (data:any) => {
    return data.map((item:any) => ({
      name: item.name
    }));
  }

}

export default new PostDetailHelper();