class CreateUserHelper {
  getOrganisationsForSelect = (data:any) => {
    const organisationArray = data.map((organisation:any) => (
        {
          value: organisation.id,
          label: organisation.name,
          imageUrl: organisation.img,
          about: organisation.about,
          link: organisation.link
        }
      ));
      return organisationArray;
  }

  getOrganisationTypeForSelect = (data:any) => {
    const organisationTypeArray = data.map((organisation:any) => {
      return {
        value: organisation.id,
        label: organisation.label
      }
    });
    return organisationTypeArray;
  }

  getGenderTypeForSelect = (data:any) => {
    const genderArray = data.map((gender:any) => {
      return {
        value: gender.id,
        label: gender.label
      }
    });
    return genderArray;
  }

  getUserTypeName = (id:any) => {
    if(id === 4) {
      return "StakeHolder";
    } else {
      return "Sponsor";
    }
  }

  selectedTheme = (theme:any) => {
    return ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#A1B3C9",
        primary: "#A1B3C9",
        neutral0: "#0D3058",
        primary50: "#A1B3C9"
      }
    })
  }

  getSelectedOrganisation = (data:any, id:any) => {    
    return data.filter((dataItem:any) => dataItem.value === id).map((item:any) => ({
      value: item.value,
      label: item.label,
      imageUrl: item.imageUrl,
      about: item.about,
      link: item.link
    }));
  }

  getSelectedOrganisationType = (data:any, id:any) => {    
    console.log(data);
    console.log(id);
    return data.filter((dataItem:any) => dataItem.value === id).map((item:any) => ({
      value: item.value,
      label: item.label   
    }));
  }

}

export default new CreateUserHelper();