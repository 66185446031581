import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { Formik } from 'formik';
import { loginForm } from './login.form';

import { useAppSelector, useAppDispatch} from '../../../store/hooks';
import { login, loginSuccess, loginFail, stayLoggedInOn, stayLoggedInOff } from '../../../store/slices/login/login.slice';

import {show, hide } from '../../../store/slices/loading/loading.slice';

import AuthService from '../../../services/auth/auth.service';


import { ReactComponent as NotChecked } from "../../../assets/unchecked.svg";
import { ReactComponent as Checked } from "../../../assets/checked.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";
import { ReactComponent as Logo } from "../../../assets/log-in-logo.svg";

import "./login.page.style.scss";

interface LoginPageProps { 
  history: any;
}

const LoginPage = (props: LoginPageProps) => {
  const [userLogin, setUserLogin] = useState({email: "", password: ""});
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);

  const dispatch = useAppDispatch();

  const isLoggingIn = useAppSelector(state => state.login.isLoggingIn);
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn);
  const isStayLoggedIn = useAppSelector(state => state.login.isStayLoggedIn);

  useEffect(() => {
    if(isStayLoggedIn){
      setStayLoggedIn(true);
    } else {
      setStayLoggedIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isStayLoggedIn]) 

  useEffect(() => {
    if (isLoggingIn) {
      dispatch(show());
      AuthService.postLogin(userLogin.email, userLogin.password)
          .then((user) => {        
            if(user.status === 201 || user.status === 200) {
              dispatch(loginSuccess(user.data));
            } else if(user.status === 401) {
              dispatch(loginFail("Wrong username or password."));
            }
          })
          .catch(error => {
              console.clear();
              setShowLoginError(true);
              dispatch(loginFail(error));
          })
    } else {
      dispatch(hide());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggingIn]); 

  useEffect(()=> {
    if(isLoggedIn){
      dispatch(hide());
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggedIn]);


  const submitLogin = (userLoginData: {email: string, password: string}) => {
    setUserLogin(userLoginData);
    dispatch(login());
  }

  const toggleStayLoggedIn = () => {
    if(stayLoggedIn) {
      dispatch(stayLoggedInOff());
    } else {
      dispatch(stayLoggedInOn());
    }
  }

  const LoginError = () => {
    setTimeout(()=> {
      setShowLoginError(false);
    }, 3000);
    
    return <p className="error-message-login">
            Incorrect username or password. Please try again. <Error />
          </p>
  }

  return (
    <div className="fade-container">
      <Formik 
          initialValues={{email: "", password: ""}}
          onSubmit={submitLogin} 
          validationSchema={loginForm}
        >
        {({handleSubmit, handleChange, handleBlur, errors, setFieldTouched, touched, values}) => (
          <form className="log-in-form" onSubmit={handleSubmit}>
              
              <div className="log-in-logo">
                <Link to="/">
                  <Logo />
                </Link>
              </div>

              <h2 className="log-in-heading">Log in</h2>

              <label>
                Email
                <input
                  className="create-log-in-input"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {
                  touched.email && errors.email ?
                    <p className="error-message-login">
                        {errors.email} <Error />
                    </p>
                  : null
                }               
              </label>

              <label>
                Password
                <input
                  className="create-log-in-input"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="current-password"
                  value={values.password}
                />
                {
                  touched.password && errors.password ?
                    <p className="error-message-login">
                        {errors.password} <Error />
                    </p>
                  : null
                }
                {
                  showLoginError ? 
                    <LoginError />
                  : null
                }
              </label>

              <div className="login-button-container">
                  <button
                    type="submit"
                    className="buttons-style action">
                      <p className="button-text">
                        LOG IN
                      </p>
                  </button>
              </div>

              <div
                className="checkbox-container"
                onClick={() => toggleStayLoggedIn()}
              >
                <p className="checkbox-text">STAY LOGGED IN</p>
                <div className="checkbox">
                    {stayLoggedIn ? <Checked /> : <NotChecked />}
                </div>
              </div>

              <div className="reset-password-button-container">
                <Link to="/forgot-password">
                  <p className="register-subheading">Forgot your password?</p>
                </Link>
              </div>

              <div className="register-button-container">
                <Link to="/register-step01">
                  <p className="register-subheading-button">REGISTER</p>
                </Link>
              </div>
            </form>
        )}
      </Formik>
        
    </div>
  )
}

export default LoginPage;