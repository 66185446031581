import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Select from "react-select";
import {toSvg} from "jdenticon";

// Imported assets
import { ReactComponent as ProgressBarOne } from "../../../assets/progress-bar-2.svg";
import { ReactComponent as ProgressBarOneMobile } from "../../../assets/progress-bar-2-mobile.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";
import Forward from "../../../assets/next.png";
import Backward from "../../../assets/back.png";
import { ReactComponent as Upload } from "../../../assets/upload.svg";
import { ReactComponent as ChooseFile } from "../../../assets/choose-file.svg";
import { ReactComponent as Cancel } from "../../../assets/cancel-image.svg";

// Style related imports
import "./create-user.page.style.scss";
import customStyles from "./create-user-select.style";

// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import helper from './create-user-select.helpers';
import { baseURL } from '../../../services/helper/axios.helper';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';
import { setFetchFromStore, setOrganisationsLibrary, openExistingOrgTab, closeExistingOrgTab, setRegistrationDataInStore } from '../../../store/slices/registration/registration.slice';
import { RegistrationModel } from '../../../store/slices/registration/registration.slice.model';

export type CreateUserStep02PageProps = {
  history?: any;  
} & any;

const CreateUserStep02Page = (props: CreateUserStep02PageProps) => { 
   
    // Usestates
    const [orgTab, setOrgTab] = useState("create");
    const [uploadDeactivated, setUploadDeactivated] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [organisationsForSelect, setOrganisationsForSelect] = useState<any>([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState({selectedOrganisationOption: []});

    const [organisationTypeForSelect, setOrganisationTypeForSelect] = useState<any>([]);
    const [selectedOrganisationType, setSelectedOrganisationType] = useState({selectedOrganisationTypeOption: []});
    const profile = useAppSelector(state => state.login.profile);

    const userType = [
      { value: 4, label: "Stakeholder", id: 4 },
      { value: 6, label: "Sponsor", id: 6 }
    ];

    window.jdenticon_config = {
      hues: [220],
      lightness: {
        color: [0.38, 0.8],
        grayscale: [0.3, 0.9]
      },
      saturation: {
        color: 0.44,
        grayscale: 0.47
      },
      backColor: "#a1cbe500"
    };
  
    const SVG = toSvg(props.name, 200);
  
    function createMarkup() {
      return { __html: SVG };
    }    

    // Other hooks initilisation
    const dispatch = useAppDispatch();
    const { register, handleSubmit, setValue, formState: {errors}} = useForm({mode: "onTouched", reValidateMode: 'onChange'});
    const { getDataFromServer, registrationStarted, organisations, registrationData, openExistingOrganisationTab } = useAppSelector(state => state.registration);

    let registrationModelObject: RegistrationModel = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      c_password: "",
      isCreator: false,
      organisation_id: 0,
      img: {},
      organisationName: "",
      about: "",
      contactEmail: "",
      contactNumber: "",
      role_id: 0,
      link: ""
  };

    // Event methods
// Call to action methods
  const onSubmit = (data:any) => {    

    if(openExistingOrganisationTab) {
        registrationModelObject.organisation_id = (Array.isArray(data.selectedOrganisation) ? data.selectedOrganisation[0].value : data.selectedOrganisation.value);
        registrationModelObject.about = registrationData.about;
        registrationModelObject.img = registrationData.img;
        registrationModelObject.organisationName = registrationData.organisationName;
      } else {
        if(profile.roleId === 1) {
          registrationModelObject.role_id = (Array.isArray(data.selectedOrganisationType) ? data.selectedOrganisationType[0].value :  data.selectedOrganisationType.value);
        }        
        registrationModelObject.organisationName = data.organisationName;
        registrationModelObject.img = selectedFile;
        registrationModelObject.about = data.aboutOrganisation;
        registrationModelObject.link = data.link;
      }

      registrationModelObject.firstName = data.firstName;
      registrationModelObject.lastName = data.lastName;    
      registrationModelObject.isCreator = registrationData.isCreator;
      registrationModelObject.contactEmail = registrationData.contactEmail;
      registrationModelObject.contactNumber = data.contactNumber;

      registrationModelObject.email = registrationData.email;
      registrationModelObject.password = registrationData.password;
      registrationModelObject.c_password = registrationData.c_password;

      dispatch(setRegistrationDataInStore(registrationModelObject));

      props.history.push(`/create-user/step-3`);
    }

    const onChangeHandler = (event:any) => {
      const type =
        event.target.files[0] !== undefined ? event.target.files[0].type : null;
      if (type === "image/jpeg" || type === "image/png") {
        setSelectedFile(event.target.files[0]);
        setFile(URL.createObjectURL(event.target.files[0]));
        setUploadDeactivated(true);
        // setIsUploadFile(true);
      } else if (type === null) {
        return null;
      } else {
        alert("please upload either a .png or .jpeg");
      }
    };

    const handleOrganisationChange = (selectedOrganisationOption:any) => {
      setValue("selectedOrganisation", selectedOrganisationOption);
      setSelectedOrganisation({ selectedOrganisationOption });
    }

    const handleOrgnisationTypeChange = (selectedOrganisationTypeOption:any) => {
      setValue("selectedOrganisationType", selectedOrganisationTypeOption);
      setSelectedOrganisationType({ selectedOrganisationTypeOption });
    }

    const clearFile = () => {
      setSelectedFile(null);
      setFile(null);
      setUploadDeactivated(false);
    };

    // useEffect hooks
    useEffect(() => {
      if(openExistingOrganisationTab) {

        let selectedOrganisationOption:any = helper.getSelectedOrganisation(organisations, registrationData.organisation_id);  
        setValue("selectedOrganisation", selectedOrganisationOption);
        setSelectedOrganisation(() => ({selectedOrganisationOption}));  
        setValue("firstName", registrationData.firstName);
        setValue("lastName", registrationData.lastName);
        setValue("contactNumber", registrationData.contactNumber);
  
        setOrgTab("existing");
      } else {


        let selectedOrganisationTypeOption:any = helper.getSelectedOrganisationType(userType, registrationData.role_id);  
        setValue("selectedOrganisationType", selectedOrganisationTypeOption);
        setSelectedOrganisationType(() => ({selectedOrganisationTypeOption}))


        if(registrationData.img) {
          setSelectedFile(registrationData.img);
          const binaryData = [];
          binaryData.push(registrationData.img);
          setFile(URL.createObjectURL(new Blob(binaryData, {type: "application/zip"})));
          setUploadDeactivated(true);
          // setIsUploadFile(true);  
        } else {
          clearFile();
        }      
  
        setValue("firstName", registrationData.firstName);
        setValue("lastName", registrationData.lastName);
        setValue("contactNumber", registrationData.contactNumber);
        setValue("link", registrationData.link);
        setValue("aboutOrganisation", registrationData.about);
        setValue("organisationName", registrationData.organisationName);
  
        setOrgTab("create");
      }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

      if(!registrationStarted) {
        props.history.push(`/register-step01`);
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[registrationStarted]);
  
    useEffect(() => {
      
      if(getDataFromServer) {
        clearFile();
        dispatch(show());            
        ApiService.getOrganisations()
        .then((org) => {        
          setOrganisationsForSelect(helper.getOrganisationsForSelect(org.data));
          setOrganisationTypeForSelect(helper.getOrganisationTypeForSelect(userType));
          dispatch(setOrganisationsLibrary(helper.getOrganisationsForSelect(org.data)));
          dispatch(setFetchFromStore());
          dispatch(hide());            
        })
        .catch(() => {
          dispatch(hide());            
        });  
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[]);
  
    useEffect(() => {
      
      if(!getDataFromServer) {        
        setOrganisationsForSelect(organisations);
        setOrganisationTypeForSelect(helper.getOrganisationTypeForSelect(userType));
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[getDataFromServer])

      const imageUrl:any = (baseURL ? baseURL : "") + "/";

      return (
        <>
            <div className="heading-container">
              <h2 className="heading-text">REGISTER A CONTRIBUTOR – ADD DETAILS</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">
                <div className="progress-bar-container">
                  {window.innerWidth > 400 ? (
                    <ProgressBarOne width="100%" />
                  ) : (
                    <ProgressBarOneMobile width="75%" />
                  )}
                </div>

                { profile.roleId === 1 && (

                    <div className="organisation-buttons">
                      <div
                        onClick={() => dispatch(closeExistingOrgTab())}
                        className={
                          orgTab === "create"
                            ? "organisation-button left selected"
                            : "organisation-button left"
                        }
                      >
                        <p>Create New Organisation</p>
                      </div>
                      <div
                        onClick={() => dispatch(openExistingOrgTab())}
                        className={
                          orgTab === "existing"
                            ? "organisation-button right selected"
                            : "organisation-button right"
                        }
                      >
                        <p>Add To Existing Organisation</p>
                      </div>
                    </div>

                )}
                

                {orgTab === "create" &&  (profile.roleId === 1) && (
                    <div className="fade-container">
                      <label>
                        Profile Image
                        <div
                          className={
                            !uploadDeactivated
                              ? "image-container-activated"
                              : "image-container-deactivated"
                          }
                        >
                          <input
                            type="file"
                            className="form-control"
                            onChange={onChangeHandler}
                            disabled={uploadDeactivated}
                          />
                          {file && (
                              <div className="preview-image-container">
                                {selectedFile.name !== "icon" ? (
                                  <img src={file} className="preview-image" alt="logo" />
                                ) : (
                                  <div dangerouslySetInnerHTML={createMarkup()} />
                                )}
                              </div>
                          )}
                          {!file && (
                            <>
                              <div className="upload-item">
                                <Upload />
                                <p className="image-subheading">DRAG FILE TO UPLOAD</p>
                              </div>
                              <div className="choosefile-item">
                                <ChooseFile />
                                <p className="image-subheading">CHOOSE FILE</p>
                              </div>
                            </>
                          )}
                          <div className="file-name-item">
                            {selectedFile ? (
                              <>
                                <p>
                                  {selectedFile &&
                                    (selectedFile.name === "icon"
                                      ? "Generated Avatar"
                                      : selectedFile.name)}
                                </p>
                                <button
                                  type="button"
                                  className="delete-file-button"
                                  onClick={() => clearFile()}
                                >
                                  <Cancel />
                                </button>
                              </>
                            ) : (
                              <p className="upload-image-text">
                                Select an image or leave empty to generate a unique avatar
                              </p>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                )}

                {orgTab === "existing" && (
                      <div className="fade-container">
                        <label>
                          Organisation Name
                          <Select
                            {...register("selectedOrganisation", {required: true })}
                            styles={customStyles}                            
                            options={organisationsForSelect}
                            onChange={handleOrganisationChange}
                            value={selectedOrganisation.selectedOrganisationOption}
                            placeholder="Select an organisation"
                            theme={theme => helper.selectedTheme(theme)}
                          />
                          {errors.selectedOrganisation && <p className="error-message">Oganisation name is required<Error /></p>}
                        </label>
                      </div>
                )}

                {orgTab === "create"  &&  (profile.roleId === 1) && (
                  <div className="fade-container">
                      <label>
                        Organisation Type
                        <Select
                          {...register("selectedOrganisationType", {required: true })}
                          styles={customStyles}       
                          options={organisationTypeForSelect}
                          onChange={handleOrgnisationTypeChange}
                          value={selectedOrganisationType.selectedOrganisationTypeOption}
                          placeholder="Select one"
                          theme={theme => helper.selectedTheme(theme)}
                          menuShouldScrollIntoView={true}
                        />
                        {errors.selectedOrganisationType && <p className="error-message">Oganisation type is required<Error /></p>}
                      </label>

                      <label htmlFor="organisationName">
                        Organisation Name
                        <input
                          id="organisationName"                          
                          type="text"
                          {...register("organisationName", {required: true, minLength: 3 })}
                        />
                        {errors.organisationName && <p className="error-message">Organisation name required<Error /></p>}
                      </label>
                      <label>
                        About Organisation
                        <textarea
                          {...register("aboutOrganisation", {required: true, minLength:3 })}
                        />
                        {errors.aboutOrganisation && (
                          <p className="error-message">
                            Add description about your organisation
                            <Error />
                          </p>
                        )}
                      </label>                      
                  </div>
                )}

                {orgTab === "existing" && (
                  <>
                      <div className="fade-container">
                        <label>
                          Profile Image
                          <div className="image-container">
                          <img
                              className="organisation-card-image"
                              style={!imageLoaded ? { display: "none" } : {width: "200px"}}
                              src={imageUrl}
                              onLoad={() => setImageLoaded(true)}
                              alt="a logo that visually represents the Organisation"
                            />
                            { !imageLoaded ? <div className="image-jdenticon" dangerouslySetInnerHTML={createMarkup()} /> : null }    
                          </div>
                        </label>
                      </div>
                      <div className="fade-container">
                        <label>
                          About Organisation
                          <textarea
                            {...register("aboutOrganisation", {required: true, minLength:3 })}
                            readOnly
                          />
                        </label>
                      </div>                      
                  </>
                )}

                <div className="fade-container">
                  <label>
                    First Name
                    <input
                      {...register("firstName", {required: true, minLength:3 })}
                    />
                    {errors.firstName && (
                      <p className="error-message">
                        Add Contact Persons First Name
                        <Error />
                      </p>
                    )}
                  </label>
                  <label>
                    Last Name
                    <input
                      {...register("lastName", {required: true, minLength:3 })}
                    />
                    {errors.lastName && (
                      <p className="error-message">
                        Add Contact Persons Last Name
                        <Error />
                      </p>
                    )}
                  </label>
                  <label>
                    Contact Number
                    <input
                      {...register("contactNumber", {required: true, minLength:3 })}
                    />
                    {errors.contactNumber && (
                      <p className="error-message">
                        Add contact persons number
                        <Error />
                      </p>
                    )}
                  </label>
                  <label>
                    Link
                    <input
                      {...register("link", {required: true, minLength:3 })}
                    />
                    {errors.link && (
                      <p className="error-message">
                        Add a link to organisation profile
                        <Error />
                      </p>
                    )}
                  </label>

                  <div className="button-container">
                    <CustomButton 
                        butonStyle="navigation" 
                        onClick={() => props.history.goBack()} 
                        iconLeft={true} 
                        icon={Backward}
                      >
                      BACK
                    </CustomButton>   
                    <CustomButton 
                        butonStyle="navigation" 
                        iconRight={true} 
                        icon={Forward}
                        type="submit"
                      >
                      NEXT
                    </CustomButton> 
                  </div>
                </div>

            </form>
        </>
    )
    
}

export default CreateUserStep02Page;