import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {useEffect} from 'react';

import AuthService from './services/auth/auth.service';

import { useAppDispatch, useAppSelector } from './store/hooks';
import { userCheckSuccess, checking, userCheckFail } from './store/slices/login/login.slice';

// Load pages
import AboutPage from './app/pages/about/about.page';
import AllPostingsListPage from './app/pages/postings/all-postings/all-postings-list.page';
import AllPublishedPostingsListPage from './app/pages/postings/all-postings/all-published-postings-list.page';
import ChangePasswordPage from './app/pages/change-password/change-password.page';
import ConfirmPasswordPage from "./app/pages/confirm-password/confirm-password.page";
import ContactPage from "./app/pages/contact/contact.page";
import CreatePostHelpPage from "./app/pages/help/create-post-help.page";
import EditPostHelpPage from "./app/pages/help/edit-post-help.page";
import DeletePostHelpPage from "./app/pages/help/delete-post-help.page";
import CreatePostingStep01Page from "./app/pages/postings/create-posting/create-posting-step01.page";
import CreatePostingStep02Page from "./app/pages/postings/create-posting/create-posting-step02.page";
import CreatePostingStep03Page from "./app/pages/postings/create-posting/create-posting-step03.page";
import CreateUserStep01Page from "./app/pages/create-user/create-user-step01.page";
import CreateUserStep02Page from "./app/pages/create-user/create-user-step02.page";
import CreateUserStep03Page from "./app/pages/create-user/create-user-step03.page";
import DashboardAdminPage from "./app/pages/dashboard/admin/dashboard-admin.page";
import DashboardSponsorsPage from "./app/pages/dashboard/sponsor/dashboard-sponsor.page";
import DashboardStakeholderPage from "./app/pages/dashboard/stakeholder/dashboard-stakeholder.page";
import FaqPage from "./app/pages/faq/faq.page";
import FirstTimeLoginPageProps from "./app/pages/first-time-login/first-time-login.page";
import EditPostingStep01Page from "./app/pages/postings/edit-posting/edit-posting-step01.page";
import EditPostingStep02Page from "./app/pages/postings/edit-posting/edit-posting-step02.page";
import EditPostingStep03Page from "./app/pages/postings/edit-posting/edit-posting-step03.page";
import EditProfilePage from "./app/pages/edit-profile/edit-profile.page";
import GettingStartedPage from "./app/pages/getting-started/getting-started.page";
import ForgotPasswordPage from "./app/pages/forgot-password/forgot-password.page";
import HomePage from './app/pages/home/home.page';
import LoginPage from './app/pages/login/login.page';
import NotFoundPage from './app/pages/not-found/not-found.page';
import OrganisationsPage from "./app/pages/organisations/organisations.page";
import OrganisationProfilePage from "./app/pages/organisations/organisation-profile.page";
import EditOrganisationProfileProps from "./app/pages/organisations/edit-organisation-profile.page";
import PendingPostingsListPage from "./app/pages/postings/pending/pending-postings-list.page";
import PostDetailPage from "./app/pages/postings/detail/post-detail.page";
import PublishedPostingsListPage from "./app/pages/postings/published/published-postings-list.page";
import RegisterStep01Page from "./app/pages/registration/register-step01.page";
import RegisterStep02Page from "./app/pages/registration/register-step02.page";
import RegisterStep03Page from "./app/pages/registration/register-step03.page";
import ScrollToTop from "./app/components/scroll-to-top/scroll-to-top.component";
import SplashPage from './app/pages/splash/splash.page';
import StudentLoginErrorPage from "./app/pages/student-error/student-login-error.page";

// Load components
import Footer from "./app/components/footer/footer.component";
import LoadingComponent from "./app/components/loading/loading.component";
import StaticLoadingComponent from "./app/components/loading/static-loading.component";

import './styles/App.scss';

import ProtectedRoute, { ProtectedRouteProps } from './app/components/protectedroute.component';
import Header from "./app/components/header/header.component";


function App() {

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    authenticationPath: '/login'
  };

  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn);
  const isChecking = useAppSelector(state => state.login.isChecking);
  
  useEffect(() => {
    if(!isLoggedIn) {
        dispatch(checking());
        AuthService.getUserDetails()
            .then((user) => {
                dispatch(userCheckSuccess(user.data));
            })
            .catch((error) => {
                dispatch(userCheckFail(error));
            })
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggedIn]);
  
  if (isChecking) {
    return <StaticLoadingComponent />;
  }

  return (
    <Router>
      <div className="screen-container">
        <ScrollToTop />
        <LoadingComponent />
        <Route component={Header} path="/" />
        <Switch>          
          <Route component={HomePage} path="/" exact />
          <Route component={SplashPage} path="/splash" exact />
          <Route component={RegisterStep01Page} path="/register-step01" exact />
          <Route component={RegisterStep02Page} path="/register-step02" exact />
          <Route component={RegisterStep03Page} path="/register-step03" exact />
          <Route component={LoginPage} path="/login" exact />
          <Route component={ForgotPasswordPage} path="/forgot-password" exact />
          <Route component={ConfirmPasswordPage} path="/reset-password-form" exact />
          <Route component={FirstTimeLoginPageProps} path="/first-login" exact />
          <Route component={ChangePasswordPage} path='/change-password' exact />
          <ProtectedRoute                            
              {
                ...defaultProtectedRouteProps
              }
              allowedRoles={["Administrator"]}
              exact={true}
              path='/dashboard-admin'
              component={DashboardAdminPage}
          />   
          <ProtectedRoute              
              {
                ...defaultProtectedRouteProps
              }
              allowedRoles={["Stakeholder"]}
              exact={true}
              path='/dashboard-stakeholder'
              component={DashboardStakeholderPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              exact={true}
              allowedRoles={["Sponsor"]}
              path='/dashboard-sponsor'
              component={DashboardSponsorsPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/about'
              component={AboutPage}
          />        
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/help/create-a-post'
              component={CreatePostHelpPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/help/edit-a-post'
              component={EditPostHelpPage}
          />      
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/help/delete-a-post'
              component={DeletePostHelpPage}
          />                
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/help/getting-started-list'
              component={GettingStartedPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/help/FAQ-list'
              component={FaqPage}
          />          
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/help/contact'
              component={ContactPage}
          />                    
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/postings/everything'
              component={AllPublishedPostingsListPage}
          />           
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/postings/all'
              component={AllPostingsListPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/postings/published'
              component={PublishedPostingsListPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/postings/pending'
              component={PendingPostingsListPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/post/:id'
              component={PostDetailPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/create-post/step-1'
              component={CreatePostingStep01Page}
          />   
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/create-post/step-2'
              component={CreatePostingStep02Page}
          />                  
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/create-post/step-3'
              component={CreatePostingStep03Page}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/edit-post/step-1/:id'
              component={EditPostingStep01Page}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/edit-post/step-2/:id'
              component={EditPostingStep02Page}
          />      
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/edit-post/step-3/:id'
              component={EditPostingStep03Page}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["Administrator", "Sponsor"]}
              exact={true}
              path='/create-user/step-1'
              component={CreateUserStep01Page}
          />             
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["Administrator", "Sponsor"]}
              exact={true}
              path='/create-user/step-2'
              component={CreateUserStep02Page}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["Administrator", "Sponsor"]}
              exact={true}
              path='/create-user/step-3'
              component={CreateUserStep03Page}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/organisations'
              component={OrganisationsPage}
          />
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["All"]}
              exact={true}
              path='/edit-profile-stakeholder'
              component={EditProfilePage}
          />                  
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["Administrator"]}
              exact={true}
              path='/organisation-profile/:id'
              component={OrganisationProfilePage}
          />    
          <ProtectedRoute
              {...defaultProtectedRouteProps}
              allowedRoles={["Administrator"]}
              exact={true}
              path='/edit-organisation-profile/:id'
              component={EditOrganisationProfileProps}
          />                                             
          <Route component={StudentLoginErrorPage} path="/student-login-error" exact />         
          <Route component={NotFoundPage} />
        </Switch>        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
