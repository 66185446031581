import { useState, useEffect } from "react";
import { RouteProps } from 'react-router';
import { Link } from "react-router-dom";

import ApiService from '../../../services/api/api.service';

import { useAppDispatch } from '../../../store/hooks';
import {show, hide } from '../../../store/slices/loading/loading.slice';

import "./splash.page.style.scss";

import { ReactComponent as CloseButton } from "../../../assets/close-button-splash.svg";
import { ReactComponent as AboutButton } from "../../../assets/question-button-splash.svg";
import { ReactComponent as Logo } from "../../../assets/pūngao-torohū-splash.svg";

export type SplashPageProps = {
  history?: any;  
} & RouteProps;

const SplashPage = (props: SplashPageProps) => {
  const [showText, setShowText] = useState(false);
  const [showTextLoaded, setShowTextLoaded] = useState(false);
  const [landingText, setLandingText] = useState<boolean | string>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {    
    if(showText && !showTextLoaded){
      fetchLandingText();
      setShowTextLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showText, showTextLoaded]);

  const fetchLandingText = () => {

    dispatch(show());

    ApiService.getLandingText()
      .then((res: any) => {
        setLandingText(res.data.contentText);
        dispatch(hide());
      })
      .catch(() => {
        dispatch(hide()); 
      })
  }

  const clickShowText = () => {
    setShowText(!showText)
  }

  return (
    <>
      <div className="splash-page">
        <div
          role="button"
          onClick={clickShowText}
          title="clickShowTextBtn"
          className="about-button-container"
        >
          {showText ? <CloseButton /> : <AboutButton />}
        </div>
        {showText ? (
          <p className="about-text"  data-testid="landingTextID" role="alert">{landingText}</p>
        ) : (
          <div className="logo-container">            
            <Link to="./login">
              <div className="big-logo-center">
                {window.innerWidth > 400 ? (
                  <Logo width="100%" />
                ) : (
                  <Logo width="90%" />
                )}
              </div>
            </Link>
            <div className="text-container">
              <p className="home-text">
                Connecting students to resources and career opportunities{" "}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  )

}

export default SplashPage;