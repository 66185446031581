import { createSlice } from '@reduxjs/toolkit'
import { LoadingSliceModel } from './loading.slice.model';
import { AppInitialState } from '../../app.initial.state';

import { fetchUser } from '../login/login.slice';

const initialState:LoadingSliceModel = AppInitialState.loading;

export const LoadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    show: (state) => {
      state.show = true
    },
    hide: (state) => {
      state.show = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.show = true;
      })
      .addCase(fetchUser.fulfilled, (state) => {
        state.show = false;
      })
  }
})

export const {show, hide} = LoadingSlice.actions;

export default LoadingSlice.reducer;