import axios from '../helper/axios.helper';
import { StorageService as storage } from "../storage/storage.service";

class APIService {

  config() {
    const token = storage.getItem("ptToken");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    };

    return config;
  }

  configNoToken(){
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return config;
  }

  getLandingText() {
    return axios.get("/api/landing-text");
  }

  getAllPosts() {
    return axios.get("/api/postings/users", this.config());
  }

  getPublishPostById(id:any) {
    return axios.get(`/api/posting-publish/${id}`, this.config());
  }

  getUnPublishPostById(id:any) {
    return axios.get(`/api/posting-unpublish/${id}`, this.config());
  }

  getPostById(id:any) {
    return axios.get(`/api/posting/${id}`, this.config());
  }

  deletePostById(id:any) {
    return axios.delete(`/api/posting/${id}`, this.config());
  }

  postUpdatePost(form:FormData, id:any) {
    return axios.post(`/api/posting-update/${id}`, form, this.config());
  }

  postUpdateStakeholder(form:FormData) {
    return axios.post(`/api/profile/update-stakeholder`, form, this.config());
  }  

  postCreatePost(form:FormData) {
    return axios.post('/api/posting', form, this.config());
  }

  getPostTypes() {
    return axios.get("/api/types", this.config());
  }

  getPostViewCountByPostId(id:any) {
    return axios.get(`/api/post-view-count-unique-bypostid/${id}`, this.config());
  }

  getFavorites() {
    return axios.get("/api/favourites", this.config());
  }
  
  getDepartments() {
    return axios.get("/api/departments", this.configNoToken());
  }

  getLevels() {
    return axios.get("/api/levels", this.configNoToken());
  }

  getIdentities() {
    return axios.get("/api/identities", this.configNoToken());
  }

  getGenders() {
    return axios.get("/api/genders", this.configNoToken());
  }

  getPublishedPosts() {
    return axios.get("/api/postings/stakeholders", this.config());
  }

  getOrganisations() {
    return axios.get("/api/organisations", this.configNoToken());
  }

  getOrganisationById(id:any) {
    return axios.get(`/api/organisation/show/${id}`, this.config());
  }

  getUserDetails() {
    return axios.get(`/api/details`, this.config());
  }

  postUpdateOrganisation(form:FormData, id:any) {
    return axios.post(`/api/organisation/update/${id}`, form, this.config());
  }

  getAllSponsors() {
    return axios.get("/api/allsponsor", this.configNoToken());
  }

  getAllLibraries() {
    return axios.get("/api/libraries", this.configNoToken());
  }

  getCheckEmail(email:string) {
    return axios.get(`/api/user-checkemail/${email}`, this.configNoToken());
  }

  postRegisterStakeHolder(form:FormData) {
    return axios.post("/api/register-stakeholder", form, this.configNoToken());
  }

  postRegisterUserByAdmin(form:FormData) {
    return axios.post("/api/register-user-by-admin", form, this.configNoToken());
  }


}

export default new APIService();