import { RouteProps } from 'react-router';
import { useEffect, useState } from 'react';
import "./confirm-password.style.scss";
import { Link } from "react-router-dom";
import queryString from "query-string";

import { ReactComponent as Logo } from "../../../assets/log-in-logo.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";

import { Formik } from 'formik';
import { confirmPasswordValidationForm } from './confirm-password.form';

import {useAppDispatch} from '../../../store/hooks';
import {show, hide } from '../../../store/slices/loading/loading.slice';

import AuthService from '../../../services/auth/auth.service';
import { StorageService as storage } from "../../../services/storage/storage.service";

export type ConfirmPasswordPageProps = {
  history: any;
} & RouteProps;


const ConfirmPasswordPage = (props : ConfirmPasswordPageProps) => {
  // const [confirmationForm, setConfirmationForm] = useState({email: "", password: "", confirmPassword: ""});
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);

  let values:any;
  let token:string = "";

  if(props.location) {
    values = queryString.parse(props.location.search);
    token = values.token;
  }

  const dispatch = useAppDispatch();

  const submitConfirmPassword = (confirmationPasswordData: {email: string, password: string, confirmPassword: string}) => {
    // setConfirmationForm(confirmationPasswordData);
  
    dispatch(show());
    AuthService.postForgetPassword(confirmationPasswordData.email, confirmationPasswordData.password, confirmationPasswordData.confirmPassword, token)
      .then(() => {
        setPasswordConfirmed(true);
        dispatch(hide());
      })
      .catch((error) => {        
        dispatch(hide());
      });
  }

  let forgetEmail:string = "";

  useEffect(() => {
    forgetEmail = storage.getItem('forgetPasswordEmail');
    console.log(forgetEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <>
        <Formik 
          enableReinitialize
          initialValues={{email: forgetEmail, password: "", confirmPassword: ""}}
          onSubmit={submitConfirmPassword} 
          validationSchema={confirmPasswordValidationForm}
        >
        {({handleSubmit, handleChange, handleBlur, errors, setFieldTouched, touched, values}) => (

            <form className="log-in-form" onSubmit={handleSubmit}>
                  <div className="log-in-logo">
                    <Link to="/">
                      <Logo />
                    </Link>
                  </div>

                  { 
                    !passwordConfirmed ?
                        <>
                          <h2 className="log-in-heading">CHANGE PASSWORD</h2>
                          <div className="change-password-text-container">
                            <p className="change-password-text">
                              Create a new password for Pūngao Torohū using the form below:
                            </p>
                            <p className="change-password-text">
                              Make sure your password is at least 8 characters.
                            </p>
                          </div>
                          <label>
                            Email
                            <input
                              className="create-log-in-input"
                              name="email"
                              autoComplete="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}  
                            />
                            {
                              touched.email && errors.email ?
                                <p className="error-message-login">
                                    {errors.email} <Error />
                                </p>
                              : null
                            }                       
                          </label>
                          <label>
                            Password
                            <input
                              className="create-log-in-input"
                              name="password"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}                        
                            />
                            {
                              touched.password && errors.password ?
                                <p className="error-message-login">
                                    {errors.password} <Error />
                                </p>
                              : null
                            }  
                          </label>

                          <label>
                            Re-enter your new Password
                            <input
                              className="create-log-in-input"
                              name="confirmPassword"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}                        
                            />
                            {
                              touched.confirmPassword && errors.confirmPassword ?
                                <p className="error-message-login">
                                    {errors.confirmPassword} <Error />
                                </p>
                              : null
                            }                     
                          </label>
                          <div className="login-button-container">
                                <button
                                  type="submit"
                                  className="buttons-style action">
                                    <p className="button-text">
                                    CHANGE PASSWORD
                                    </p>
                                </button>                    
                          </div>
                      </>
                    : 
                     <>
                      <div className="register-button-container">
                        <p className="success-message-login">
                           Your password has been successfully changed!
                        </p>                     
                      </div>
                      <div className="register-button-container">
                        <Link to="/login">
                          <p className="register-subheading">Back to Log In</p>
                        </Link>
                      </div>
                    </>
                  }
                  

            </form>

        )}
      </Formik>
    </>
  )
}

export default ConfirmPasswordPage;