import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from '../../../store/hooks';
import AuthService from '../../../services/auth/auth.service';
import {show, hide } from '../../../store/slices/loading/loading.slice';
import { logOut } from '../../../store/slices/login/login.slice';
import { setFetchFromServer } from '../../../store/slices/edit-post/edit-post.slice';

interface HeaderAdminProps { 
  history?: any;
}

const HeaderAdmin = (props: HeaderAdminProps) => {
  const [checked, setChecked] = useState(false);

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  
  const checkHandler = (menuItem:string) => {

    if(menuItem === 'create-posting') {
      dispatch(setFetchFromServer());
    }

    setChecked(!checked);
  };

  const logoutHanlder = () => {
      dispatch(show());
      AuthService.getLogout().then(() => {
        dispatch(logOut());
        dispatch(hide());
        props.history.push('/login');
      })
  }

  return (
    <>
      <div
        className={checked ? "screen-mask" : "screen-mask-none"}
        onClick={() => checkHandler("none")}
      ></div>
      <div className="nav-container">
          <div className="checkbox-wrapper">
            <input type="checkbox" checked={checked} onChange={() => checkHandler("none")} />
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div id={checked ? "menu-checked" : "menu-unchecked"}>

            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink to="/dashboard-admin" exact className="normal" activeClassName="active">
                HOME
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink
                to="/organisations"
                className="normal"
                activeClassName="active"
              >
                ORGANISATIONS
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink
                to="/postings/pending"
                className="normal"
                activeClassName="active"
              >
                REVIEW SUBMISSIONS
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink
                to="/postings/all"
                className="normal"
                activeClassName="active"
              >
                VIEW POSTINGS
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("create-posting")}>
              <NavLink to="/create-post/step-1" className="normal" activeClassName="active">
                CREATE POSTING
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink
                to="/create-user/step-1"
                className="normal"
                activeClassName="active"
              >
                REGISTER A USER
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink to="/change-password" className="normal" activeClassName="active">
                CHANGE PASSWORD
              </NavLink>
            </div>              
            <div className="nav-item-logout" onClick={logoutHanlder}>
              <div>LOGOUT</div>
            </div>
          </div>
        

      </div>
    </>
  );
}

export default HeaderAdmin;