import ContactCard from "../../components/contact-card/contact-card.component";

import JasonImage from "../../../assets/jason-image.jpg";
import PlaceholderImage from "../../../assets/davisdimalen.png";


import "./contact.page.scss";

const Contact = () => {
  return (
    <>
      <div className="heading-container">
          <h2 className="heading-text">CONTACT US</h2>
      </div>
      <div className="contact-container">
        <ContactCard
          heading="Program and Adminstration Queries"
          name="Jason Tutara"
          mailtoemail="mailto:j.tutara@auckland.ac.nz"
          email="j.tutara@auckland.ac.nz"
          phone="+64 22 516 1548"
          image={JasonImage}
        />
        <ContactCard
          heading="Website Feedback and Issues"
          name="Davis Dimalen"
          mailtoemail="mailto:d.dimalen@auckland.ac.nz"
          email="d.dimalen@auckland.ac.nz"
          phone="+64 22 010 0101"
          image={PlaceholderImage}
        />
      </div>
    </>
  );
}

export default Contact;
