import React from "react";
import { ReactComponent as LoadingWheel } from "../../../assets/loading.svg";
import { useAppSelector } from '../../../store/hooks';

import "./loading.component.style.scss";

const LoadingComponent = () => {

  const showLoading = useAppSelector((state) => state.loading.show);

  return (
    showLoading ?
    <div className="loading-icon">
      <LoadingWheel />
    </div>
    : null
  );
}

export default LoadingComponent;