import { Redirect, Route, RouteProps } from 'react-router';
import { useAppSelector } from '../../store/hooks';

export type ProtectedRouteProps = {
  history?: any;
  authenticationPath: string;
  allowedRoles?: Array<string>;
} & RouteProps;

export default function ProtectedRoute({authenticationPath, allowedRoles, ...routeProps}: ProtectedRouteProps):any {
  
  const isLoggedIn = useAppSelector((state) => state.login.isLoggedIn);
  const profile = useAppSelector((state) => state.login.profile);

  let allowAccess = false;  
  
  if(profile) {  
    if(allowedRoles?.includes("All")) {
      allowAccess = true;
    } else if (allowedRoles?.includes(profile.roleName)) {
      allowAccess = true;
    }
  }

  if(isLoggedIn) {    
    if (allowAccess) {
      return <Route {...routeProps} />;
    } else {
      return <Redirect to={{ pathname: authenticationPath }} />;
    }    
  } else {
      return <Redirect to={{ pathname: "/", state: routeProps.location }} />;  
  }
};