import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import {toSvg} from "jdenticon";

// Imported assets
import { ReactComponent as Error } from "../../../assets/error.svg";
import { ReactComponent as Cancel } from "../../../assets/cancel.svg";
import { ReactComponent as Upload } from "../../../assets/upload.svg";
import { ReactComponent as ChooseFile } from "../../../assets/choose-file.svg";
import Publish from "../../../assets/publish.png";
import Backward from "../../../assets/back.png";


// Style related imports
import "./edit-profile.page.style.scss";

// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import { baseURL } from '../../../services/helper/axios.helper';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';


export type EditProfilePageProps = {
  history?: any;  
} & any;

const EditProfilePage = (props: EditProfilePageProps) => {

  // useStates
  const [uploadDeactivated, setUploadDeactivated] = useState(true);
  const [selectedFile, setSelectedFile] = useState<any>({name: ""});
  const [file, setFile] = useState<any>(null);
  const [isUploadFile, setIsUploadFile] = useState(false);

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, formState: {errors}} = useForm({mode: "onTouched", reValidateMode: 'onChange'});
  const profile = useAppSelector(state => state.login.profile);
 

  // Call to action methods
  const onSubmit = (data:any) => {
    const formData = new FormData();
    console.log(data);
    console.log(data.firstName);

    if(data.firstName){
      console.log(data.firstName);
      formData.append("firstName", data.firstName);
    }

    if(data.lastName) {
      formData.append("lastName", data.lastName);
    }

    if(data.organisationName){
      formData.append("name", data.organisationName);
    }

    if(data.aboutOrganisation) {
      formData.append("about", data.aboutOrganisation);
    }

    if(data.contactName) {
      formData.append("contactName", data.contactName);
    }

    if(data.contactEmail) {
      formData.append("contactEmail", data.contactEmail);
    }

    if(data.contactNumber) {
      formData.append("contactNumber", data.contactNumber);
    }

    if(data.link) {
      formData.append("link", data.link);
    }

    if(isUploadFile && selectedFile) {
      formData.append("img", selectedFile);
    }


    dispatch(show());
    ApiService.postUpdateStakeholder(formData)
        .then((data) => {          
          dispatch(hide());
          props.history.push(`/`);
        })
        .catch((error) => {
          console.log(error);
          dispatch(hide());
        })

  }

  window.jdenticon_config = {
    hues: [220],
    lightness: {
      color: [0.38, 0.8],
      grayscale: [0.3, 0.9]
    },
    saturation: {
      color: 0.44,
      grayscale: 0.47
    },
    backColor: "#a1cbe500"
  };

  const SVG = toSvg(props.name, 100);

  function createMarkup() {
    return { __html: SVG };
  }

  const clearFile = () => {
    setSelectedFile(null);
    setFile(null);
    setUploadDeactivated(false);
  };

  const onChangeHandler = (event:any) => {
    const type =
      event.target.files[0] !== undefined ? event.target.files[0].type : null;
    if (type === "image/jpeg" || type === "image/png") {
      setSelectedFile(event.target.files[0]);
      setFile(URL.createObjectURL(event.target.files[0]));
      setUploadDeactivated(true);
      setIsUploadFile(true);
    } else if (type === null) {
      return null;
    } else {
      alert("please upload either a .png or .jpeg");
    }
  };

  const imageUrl:any = (baseURL ? baseURL : "") + "/" + profile.img;

  useEffect(() => {

    dispatch(show());
    ApiService.getUserDetails()
        .then((userProfile:any) => {
            console.log(userProfile);
            if(userProfile.data.success.img) {
                setFile(imageUrl);
                setSelectedFile({name: userProfile.data.success.img});
                setUploadDeactivated(true);
            } else {
              clearFile();
            }

            setValue("firstName", userProfile.data.success.firstName);      
            setValue("lastName", userProfile.data.success.lastName);
            
            if(profile.isCreator) {
              ApiService.getOrganisationById(profile.organisation.id)
                      .then((organisation:any) => {
                          setValue("organisationName", organisation.name);
                          setValue("aboutOrganisation", organisation.about);      
                          setValue("contactEmail", organisation.contactEmail);      
                          setValue("contactNumber", organisation.contactNumber);
                          dispatch(hide());
                      })
                      .catch(() => {
                          dispatch(hide());
                      })   
            } else {
              dispatch(hide());
            }
        })
        .catch(() => {
          dispatch(hide());
        })


    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);


  return (
    <>
      <div className="heading-container">
        <h2 className="heading-text">Edit Profile</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
            <p className="create-paragraph">
              {profile.roleId === 4
                ? "Update your organisations details."
                : "Update organisation details"}
            </p>
            <label>
              Profile Image
              <div
                className={
                  !uploadDeactivated
                    ? "image-container-activated"
                    : "image-container-deactivated"
                }
              >
                <input
                  type="file"
                  className="form-control"
                  onChange={onChangeHandler}
                  disabled={uploadDeactivated}
                />
                {file && (
                  <div className="preview-image-container">
                    {selectedFile.name !== "icon" ? (
                      <img src={file} className="preview-image" alt="logo" />
                    ) : (
                      <div dangerouslySetInnerHTML={createMarkup()} />
                    )}
                  </div>
                )}
                {!file && (
                  <>
                    <div className="upload-item">
                      <Upload />
                      <p className="image-subheading">DRAG FILE TO UPLOAD</p>
                    </div>
                    <div className="choosefile-item">
                      <ChooseFile />
                      <p className="image-subheading">CHOOSE FILE</p>
                    </div>
                  </>
                )}
                <div className="file-name-item">
                  {selectedFile ? (
                    <>
                      <p>
                        {selectedFile &&
                          (selectedFile.name === "icon"
                            ? "Generated Avatar"
                            : selectedFile.name)}
                      </p>
                      <button
                        type="button"
                        className="delete-file-button"
                        onClick={() => clearFile()}
                      >
                        <Cancel />
                      </button>
                    </>
                  ) : (
                    <p>
                      Select another image or leave empty to generate a unique
                      avatar
                    </p>
                  )}
                </div>
              </div>
            </label>
            {(profile.isCreator !== 0) && (
              <>
                <label>
                  Organisation Name
                  <input
                    {...register("organisationName", { required: true, minLength: 3 })}  
                  />
                  {errors.organisationName && (
                    <p className="error-message">
                      Add organisation name
                      <Error />
                    </p>
                  )}
                </label>
                <label>
                  About Organisation
                  <textarea
                    {...register("aboutOrganisation", { required: true, minLength: 3 })}  
                  />
                  {errors.aboutOrganisation && (
                    <p className="error-message">
                      Add description about your organisation
                      <Error />
                    </p>
                  )}
                </label>
                <label>
                  Contact Email
                  <input
                    {...register("contactEmail", { required: true, minLength: 3 })}                                  
                  />
                  {errors.contactEmail && (
                    <p className="error-message">
                      Add contact persons email
                      <Error />
                    </p>
                  )}
                </label>

                <label>
                  Contact Number
                  <input
                    {...register("contactNumber", { required: true, minLength: 3 })}                
                  />
                  {errors.contactNumber && (
                    <p className="error-message">
                      Add contact persons number
                      <Error />
                    </p>
                  )}
                </label>
              </>
            )}
            
            <label>
              First Name
              <input
                {...register("firstName", { required: true, minLength: 3 })}  
              />
              {errors.firstName && (
                <p className="error-message">
                  Add Contact Persons First Name
                  <Error />
                </p>
              )}
            </label>
            <label>
              Last Name
              <input
                {...register("lastName", { required: true, minLength: 3 })}                  
              />
              {errors.lastName && (
                <p className="error-message">
                  Add Contact Persons Name
                  <Error />
                </p>
              )}
            </label>

            <div className="button-container">
              <CustomButton 
                  butonStyle="navigation" 
                  onClick={() => props.history.goBack()} 
                  iconLeft={true} 
                  icon={Backward}
                >
                CANCEL
              </CustomButton>   
              <CustomButton 
                  butonStyle="action" 
                  iconRight={true} 
                  icon={Publish}
                  type="submit"
                >
                SUBMIT
              </CustomButton>  

            </div>
          </form>
          <div className="bottom-page-space" />
    </>
  )

}

export default EditProfilePage;