import editImage1 from "../../../assets/edit-image-1.png";
import editImage2 from "../../../assets/create-image-3.png";
import editImage3 from "../../../assets/create-image-6.png";
import editImage4 from "../../../assets/create-image-7.png";

import "./help.page.style.scss";

const EditPostHelpPage = () => {
  return (
    <div className="getting-started-container">
      <div className="heading-container">
          <h2 className="heading-text">EDIT A POST</h2>
      </div>
      <div className="getting-started-item-container">
        <div className="getting-started-image-top">
          <img src={editImage1} alt="Edit a post" />
        </div>
        <ul>
          <li className="getting-started-list">
            You can edit any post regardless of the status, whether it is
            “Pending” or “Approved” by the administrator.
          </li>

          <li className="getting-started-list">
            To edit a post, navigate to the home screen and to the dashboard.
            Find your post and click on the preview card. The post form will
            display. On the top right hand corner, click on the “Edit” button.
          </li>
          <div className="getting-started-image">
            <img
              src={editImage2}
              alt="Edit a post update drop down selections"
            />
          </div>
          <li className="getting-started-list">
            If you would like to change any of your selections for the drop down
            inputs, hover your mouse over the selected option, and click on the
            “X” icon to remove that option. Likewise with the text fields,
            simply click on the text, and tap the “back” or “delete” button on
            your keyboard to remove any text.
          </li>

          <li className="getting-started-list">
            Complete any changes to the form, and click next to progress to the
            next stage. If an error prompt appears, read the message to find out
            how to fix it. This usually appears if the input was left blank.
          </li>

          <li className="getting-started-list">
            If your post was already “Approved”, it will be reapproved by the
            admin. An updated flag will also appear on the student app.
          </li>

          <li className="getting-started-list">
            Press the back button to move to previous stages of the form.
          </li>
          <div className="getting-started-image">
            <img
              src={editImage3}
              alt="Edit a post preview form part 1"
            />
          </div>
          <div className="getting-started-image-top">
            <img
              src={editImage4}
              alt="Edit a post preview form part 2"
            />
          </div>
          <li className="getting-started-list">
            At the end of the form, preview all the details you have entered, if
            you would like to edit and make further changes, simply click on the
            “edit” form button. Otherwise, submit the form. A submission
            successful prompt will display. You can then navigate to the home
            screen to view your dashboard. There you will see a new post that is
            pending. This post is pending approval from one of our
            administrators.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default EditPostHelpPage;
