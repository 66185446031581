import { ReactComponent as EmptyBox } from "../../../assets/empty-inbox.svg";

import "./faq.page.style.scss";

function FaqPage() {
  return (
    <>
      <div className="heading-container">
          <h2 className="heading-text">FAQ</h2>
      </div>
      <div className="contact-container">
        <div className="emptybox-center">
          <EmptyBox />
        </div>
        <p className="faq-list-heading">More info to come soon</p>
      </div>
    </>
  );
}

export default FaqPage;
