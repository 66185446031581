import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

// Imported assets
import { ReactComponent as ProgressBarOne } from "../../../assets/progress-bar-1.svg";
import { ReactComponent as ProgressBarOneMobile } from "../../../assets/progress-bar-1-mobile.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";
import Forward from "../../../assets/next.png";
import Backward from "../../../assets/back.png";

// Style related imports
import "./register.page.style.scss";


// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import CustomNotification from '../../components/prompts/custom-prompt-notification.component';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import { show, hide } from '../../../store/slices/loading/loading.slice';
import { startRegistration, setRegistrationDataInStore, setIsEmailConfirmed, setIsEmailUnConfirmed } from '../../../store/slices/registration/registration.slice';
import { RegistrationModel } from '../../../store/slices/registration/registration.slice.model';

// Props type
export type RegisterStep01PageProps = {
  history?: any;  
} & any;

//===== MAIN COMPONENT =====
const RegisterStep01Page = (props: RegisterStep01PageProps) => {

  // useStates
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  
  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { registrationStarted, registrationData, isEmailConfirmed } = useAppSelector(state => state.registration);
  const { register, handleSubmit, watch, setValue, formState: {errors} } = useForm({mode: "onTouched"});

  let registrationModelObject: RegistrationModel = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    c_password: "",
    isCreator: false,
    organisation_id: 0,
    img: {},
    organisationName: "",
    about: "",
    contactEmail: "",
    contactNumber: "",
    role_id: 0,
    link: ""
  };


  // Call to action methods
  const emailFieldChange = () => {
    dispatch(setIsEmailUnConfirmed());
  }

  const processSubmit = (data:any) => {
    registrationModelObject.email = data.email;
    registrationModelObject.password = data.password;
    registrationModelObject.c_password = data.confirmPassword;

    registrationModelObject.firstName = registrationData.firstName;
    registrationModelObject.lastName = registrationData.lastName;
    registrationModelObject.isCreator = registrationData.isCreator;
    registrationModelObject.organisation_id = registrationData.organisation_id;
    registrationModelObject.organisationName = registrationData.organisationName;
    registrationModelObject.img = registrationData.img;
    registrationModelObject.about = registrationData.about;
    registrationModelObject.contactEmail = registrationData.contactEmail;
    registrationModelObject.contactNumber = registrationData.contactNumber;
    registrationModelObject.link = registrationData.link;

    dispatch(setRegistrationDataInStore(registrationModelObject));
    props.history.push('/register-step02');
  }

  const onSubmit = (data:any) => {   

    if(!isEmailConfirmed) {
      dispatch(show());
      ApiService.getCheckEmail(data.email)
          .then((response:any) => {
            if (response.status === 200) {
              dispatch(setIsEmailConfirmed());
              processSubmit(data);
            } else if (response.status === 401) {
              setDuplicateEmailError(true);
            }
            dispatch(hide());
          })
          .catch((error) => {
            setDuplicateEmailError(true);
            dispatch(hide());
          });  
    } else {
      processSubmit(data);
    }
  }

  useEffect(() => {

    if(!registrationStarted) {
      dispatch(startRegistration());
    } else {
      setValue("email", registrationData.email);
      setValue("password", registrationData.password);
      setValue("confirmPassword", registrationData.c_password);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[registrationStarted]);

  return (
    <>
        { duplicateEmailError && (
            <CustomNotification
              onClick={() => setDuplicateEmailError(false)}
              buttonLabel="TRY AGAIN"
              isWarning={true}
              promptMessage="THIS EMAIL SEEMS TO ALREADY EXIST IN OUR SYSTEM."
            />
        )}
        <div className="heading-container">
          <h2 className="heading-text">Register</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">
            <div className="progress-bar-container">
              {window.innerWidth > 400 ? (
                <ProgressBarOne width="100%" />
              ) : (
                <ProgressBarOneMobile width="75%" />
              )}
            </div>

            <label>
              Email Address
              <input
                className="create-log-in-input"
                {...register("email", {required: true, minLength:2, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                onChange={() => emailFieldChange()}
              />
              {errors.email && (
                <p className="error-message">
                  Invalid email address
                  <Error />
                </p>
              )}
            </label>

            <label>
              Create Password
              <input
                className="create-log-in-input"
                {...register("password", {required: true, minLength:2, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/g })}
                type="password"
              />
              {errors.password && (
                <p className="error-message">
                  Password must include atleast: 7 characters, 1 uppercase
                  letter, 1 lowercase letter
                  <Error />
                </p>
              )}
            </label>

            <label>
              Confirm Password
              <input
                className="create-log-in-input"
                {...register("confirmPassword", {
                  validate: value => {
                    return value === watch("password");
                  }
                })}
                type="password"
              />
              {errors.confirmPassword && (
                <p className="error-message">
                  Password did not match
                  <Error />
                </p>
              )}
            </label>

            <div className="button-container">
                  <CustomButton 
                      butonStyle="navigation" 
                      onClick={() => props.history.goBack()} 
                      iconLeft={true} 
                      icon={Backward}
                    >
                    BACK
                  </CustomButton>   
                  <CustomButton 
                      butonStyle="navigation" 
                      iconRight={true} 
                      icon={Forward}
                      type="submit"
                    >
                    NEXT
                  </CustomButton>     
            </div>

        </form>
    </>
  )
}

export default RegisterStep01Page;