import { AppModel } from "./app.model";
import { StorageService as storage } from "../services/storage/storage.service";

export const AppInitialState: AppModel = {
    loading: {
        show: false
    },
    login: {
        token: storage.getItem("ptToken"),
        isStayLoggedIn: storage.getItem("ptSLI") ? true : false,
        error: null,
        isLoggedIn: false,
        isLoggingIn: false,
        isChecking: false,
        isRecoveredPassword: false,
        isRecoveringPassword: false,
        profile: null
    },
    post: {
        error: null,
        isFetchingPost: false,
        posts: [],
        postTypes: []
    },
    editPost: {
        getDataFromServer: false,
        openExistingOrganisationTab: false,
        firstTimeOnPage2: true,
        editPost: {
            id:60,
            authorId: 2,
            title: "",
            description: "",
            img: "",
            link: "",
            keyDate: "",
            isSent: 0,
            published: true,
            about: "",
            contactName: "",
            contactEmail: "",
            contactNumber: "",
            organisation_id: 0,
            organisation: "",
            type: [],
            departments: [],
            identities: [],
            levels: []
        },
        departments: [],
        identities: [],
        levels: [],
        types: [],
        organisations: []
    },
    registration: {
        isEmailConfirmed: false,
        getDataFromServer: true,
        openExistingOrganisationTab: false,
        registrationStarted: false,
        registrationData: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            c_password: "",
            isCreator: false,
            organisation_id: 0,
            img: null,
            organisationName: "",
            about: "",
            contactEmail: "",
            contactNumber: "",
            role_id: 0,
            link: ""
        },
        organisations: []
    }
}