import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import counterReducer from './slices/counter/counterSlice';
import loginReducer from './slices/login/login.slice';
import loadingReducer from './slices/loading/loading.slice';
import postReducer from './slices/post/post.slice';
import editPostReducer from './slices/edit-post/edit-post.slice';
import registrationReducer from './slices/registration/registration.slice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    loading: loadingReducer,
    login: loginReducer,
    post: postReducer,
    editPost: editPostReducer,
    registration: registrationReducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),  
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
