import React, { useState, ReactNode } from "react";


import "./accordion.component.style.scss";

type AccordionProps = {
  title?: string;
  children?: ReactNode
}

const Accordion = (props: AccordionProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="accordion-wrapper">
      <div className="accordion-title-container">
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          {`${isOpen ? "HIDE" : "SHOW"} ${props.title} \u00A0 ${isOpen ? "-" : "+"}`}
        </div>
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{props.children}</div>
      </div>
    </div>
  );
};

export default Accordion;
