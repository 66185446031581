import { useEffect, useState } from 'react';

// Style related imports
import "./organisations.page.style.scss";

// Custom components and helper imports
import Card from './organisations.card.component';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';


interface OrganisationsModel {
  id?: number;
  name?: string;
  about?: string;
  contactName?: string | null;
  contactEmail?: string;
  contactNumber?: string;
  creatorId?: number;
  link?: string;
  img?: string;
  created_at?: string;
  updated_at?: string;
};


export type OrganisationsPageProps = {
  history?: any;  
} & any;

const OrganisationsPage = (props: OrganisationsPageProps) => { 
  const [organisationList, setOrganisationList] = useState<OrganisationsModel[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(show());

    ApiService.getOrganisations()
      .then((organisations:any) => {
        setOrganisationList(organisations.data);
        dispatch(hide());
      })
      .catch((error) => {
        dispatch(hide());
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  return (
    <>
        <div className="heading-container">
          <h2 className="heading-text">Organisations</h2>
        </div>
        <div className="organisations-list-container">
            {organisationList.map((organisation, index) => (
                  <Card key={index} {...organisation} />
              ))
            }
        </div>
        <div className="bottom-page-space"></div>
    </>
  );
}

export default OrganisationsPage;