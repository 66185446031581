import {useState } from 'react';
import { RouteProps } from 'react-router';
import "./forgot-password.style.scss";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../../assets/log-in-logo.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";

import { Formik } from 'formik';
import { forgotPassowrdValidationForm } from './forgot-password.form';

import {useAppDispatch} from '../../../store/hooks';
import {show, hide } from '../../../store/slices/loading/loading.slice';

import AuthService from '../../../services/auth/auth.service';
import { StorageService as storage } from "../../../services/storage/storage.service";

// import { useState } from 'react';


export type ForgotPasswordPageProps = {
  history: any;
} & RouteProps;

const ForgotPasswordPage = (props : ForgotPasswordPageProps) => {
  //const [forgotPasswordForm, setForgotPasswordForm] = useState({email: ""});
  const [isSentEmail, setIsSentEmail] = useState(false);
  const [theEmail, setTheEmail] = useState('');

  const dispatch = useAppDispatch();

  const submitForgotPassword = (forgotPasswordData: {email: string}) => {
    //setForgotPasswordForm(forgotPasswordData);
    //props.history.push('/reset-password-form');

    dispatch(show());
    AuthService.postSendResetLink(forgotPasswordData.email)
        .then((response) => {
          setTheEmail(forgotPasswordData.email);
          setIsSentEmail(true);
          storage.setItem("forgetPasswordEmail", forgotPasswordData.email);
          dispatch(hide());
        })
        .catch(error => {
          dispatch(hide());
        });    
  }

  return (
    <>
        <Formik 
          initialValues={{email: ""}}
          onSubmit={submitForgotPassword} 
          validationSchema={forgotPassowrdValidationForm}
        >
        {({handleSubmit, handleChange, handleBlur, errors, setFieldTouched, touched, values}) => (

            <form className="log-in-form" onSubmit={handleSubmit}>

              <div className="log-in-logo">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <h2 className="log-in-heading">ENTER EMAIL ADDRESS</h2>
              <label>
                {
                  !isSentEmail ?
                <input
                  className="create-log-in-input"
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}                  
                />
                : 
                  <p className="success-message-login">
                      An email has been sent to {theEmail}.
                  </p>
                }
                {
                  touched.email && errors.email ?
                    <p className="error-message-login">
                        {errors.email} <Error />
                    </p>
                  : null
                }
              </label>
              {
                  !isSentEmail ?
                    <div className="login-button-container">
                        <button
                          type="submit"
                          className="buttons-style action">
                            <p className="button-text">
                            CHANGE PASSWORD
                            </p>
                        </button>
                    </div> : null
              }
              <div className="register-button-container">
                <Link to="/login">
                  <p className="register-subheading">Back to Log In</p>
                </Link>
              </div>
            </form>

        )}
      </Formik>
    </>
  )
}

export default ForgotPasswordPage;