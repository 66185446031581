import { Link } from "react-router-dom";

import './getting-started.page.style.scss';

export type GettingStartedPageProps = {
  history?: any;  
} & any;

const GettingStartedPage = (props: GettingStartedPageProps) => { 

    return (
        <>
          <div className="heading-container">
              <h2 className="heading-text">GETTING STARTED LIST</h2>
          </div>
          <Link to="/help/create-a-post">
            <div className="posting-card-container">
              <p className="guide-card-heading">Create a Post</p>
            </div>
          </Link>
          <Link to="/help/edit-a-post">
            <div className="posting-card-container">
              <p className="guide-card-heading">Edit a Post</p>
            </div>
          </Link>
          <Link to="/help/delete-a-post">
            <div className="posting-card-container">
              <p className="guide-card-heading">Delete a Post</p>
            </div>
          </Link >
        </>
    )
    
}

export default GettingStartedPage;