import deleteImage1 from "../../../assets/edit-image-1.png";
import deleteImage2 from "../../../assets/delete-image-1.png";

import "./help.page.style.scss";

const DeletePostHelpPage = () => {
  return (
    <div className="getting-started-container">
      <div className="heading-container">
          <h2 className="heading-text">DELETE A POST</h2>
      </div>
      <div className="getting-started-item-container">
                <div className="getting-started-image-top">
                    <img src={deleteImage1} alt="Delete a post" />
                </div>
                <ul>
                    <li className="getting-started-list">
                        You can delete any post regardless of the status, whether it is “Pending” or “Approved” by the administrator. Please note if you delete a post, you will be unable to undo or recover any information.
                    </li>
                    <div className="getting-started-image">
                        <img src={deleteImage2} alt="Delete a post delete button" />
                    </div>
                    <li className="getting-started-list">
                        To delete a post, navigate to the home screen and to the dashboard. Find your post and click on the preview card. The post form will display. On the bottom left hand corner, click on the “Delete” button. A warning prompt will display, read the message, and click the “delete” button if you agree.                      </li>
                </ul>
            </div>
    </div>
  );
}

export default DeletePostHelpPage;
