import React from "react";
import { ReactComponent as LoadingWheel } from "../../../assets/loading.svg";

import "./loading.component.style.scss";

const StaticLoadingComponent = () => {

  return (
    <div className="loading-icon">
      <LoadingWheel />
    </div>
  );
}

export default StaticLoadingComponent;