import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

// Imported assets
import { ReactComponent as ProgressBarTwo } from "../../../../assets/progress-bar-2.svg";
import { ReactComponent as ProgressBarTwoMobile } from "../../../../assets/progress-bar-2-mobile.svg";
import { ReactComponent as Error } from "../../../../assets/error.svg";
// import { ReactComponent as Error } from "../../../../assets/error.svg";
import Forward from "../../../../assets/next.png";
import Backward from "../../../../assets/back.png";


// Style related imports
import "./edit-posting.style.scss";
import "../../../../styles/datepicker.style.scss";


// Custom components and helper imports
// import helper from './edit-posting-select.helpers';
import CustomButton from '../../../components/custom-button/custom-button.component';

// Service and redux imports
import { useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {PostModel} from '../../../../store/slices/edit-post/edit-post.slice.model';
import { setPostInStore } from '../../../../store/slices/edit-post/edit-post.slice';

export type EditPostingStep02PageProps = {
  history?: any;  
} & any;

const EditPostingStep02Page = (props: EditPostingStep02PageProps) => { 
  const postId = props.match.params.id;

  // useStates
  const [startDate, setStartDate] = useState(new Date());

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { editPost } = useAppSelector(state => state.editPost);
  const profile = useAppSelector(state => state.login.profile);
  const { register, handleSubmit, setValue, formState: {errors}} = useForm();
  
  let postModelObject:PostModel = {
    id:60,
    authorId: 2,
    title: "",
    description: "",
    img: "",
    link: "",
    keyDate: "",
    isSent: 0,
    published: true,
    about: "",
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    organisation_id: 0,
    organisation: "",
    type: [],
    departments: [],
    identities: [],
    levels: []
  }

  const onSubmit = (data:any) => {

    postModelObject.title = data.title;
    postModelObject.description = data.description;
    postModelObject.contactName = data.contactName;
    postModelObject.contactEmail = data.contactEmail;
    postModelObject.contactNumber = data.contactNumber;
    postModelObject.organisation = data.organisation;    
    postModelObject.link = data.link;
    postModelObject.keyDate = moment(data.keyDate).format("YYYY-MM-DD hh:mm:ss");

    postModelObject.id = editPost.id;
    postModelObject.authorId = editPost.authorId;
    postModelObject.published = editPost.published;
    postModelObject.isSent = editPost.isSent;
    postModelObject.organisation_id = editPost.organisation_id;
    postModelObject.img = editPost.img;

    postModelObject.departments = editPost.departments;
    postModelObject.identities = editPost.identities;
    postModelObject.levels = editPost.levels;
    postModelObject.type = editPost.type;

    dispatch(setPostInStore(postModelObject));

    props.history.push(`/edit-post/step-3/${postId}`);
  }

  const setKeyDateHandler = (data:any) => {
    setStartDate(data);
    setValue("keyDate", data);
  };

  let organisationFieldValue = editPost.organisation;
  let titleFieldValue = editPost.title;
  let descriptionFeildValue = editPost.description;
  let contactNameFieldValue = editPost.contactName;
  let contactEmailFieldValue = editPost.contactEmail;  
  let contactNumberFieldValue = editPost.contactNumber;  
  let linkFieldValue = editPost.link;  

  useEffect(() => {

    setStartDate(new Date(editPost.keyDate));
    setValue("keyDate", new Date(editPost.keyDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps      
  },[]);
  
  return (
    <>
        <div className="heading-container">
          <h2 className="heading-text">EDIT POSTING</h2>
        </div>
            <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">
                <div className="progress-bar-container">
                  {window.innerWidth > 400 ? (
                    <ProgressBarTwo width="100%" />
                  ) : (
                    <ProgressBarTwoMobile width="75%" />
                  )}
                </div>
                <p className="create-paragraph">
                  Review details and proceed to the next step
                </p>
                
                {(profile.roleId !== 4) && (
                    <label>
                      Organisation Name
                      <input
                        {...register("organisation")}
                        onChange={e => setValue("organisation", e.target.value)}
                        defaultValue={organisationFieldValue}
                        disabled={true}
                        className="disabledField"
                      />
                      {errors.organisation && (
                          <p className="error-message">
                            Add description about your organisation
                            <Error />
                          </p>
                      )}
                    </label>
                )}
                
                <label>
                  Opportunity title
                  <input
                    {...register("title")}
                    onChange={e => setValue("title", e.target.value)}
                    defaultValue={titleFieldValue}
                  />
                </label>
                <label>
                  Description of the posting
                  <textarea
                    {...register("description")}
                    onChange={e => setValue("description", e.target.value)}
                    defaultValue={descriptionFeildValue}
                  />
                </label>
                <label>
                Event date and time
                  <DatePicker                    
                    {...register("keyDate")}
                    selected={startDate}
                    onChange={date => setKeyDateHandler(date)}
                    showTimeSelect
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    name="date"
                  />
                </label>
                <label>
                  Contact name
                  <input
                    {...register("contactName")}
                    onChange={e => setValue("contactName", e.target.value)}
                    defaultValue={contactNameFieldValue}
                  />
                </label>

                <label>
                  Contact email
                  <input
                    {...register("contactEmail")}
                    onChange={e => setValue("contactEmail", e.target.value)}
                    defaultValue={contactEmailFieldValue}
                  />
                </label>
                <label>
                  Contact number
                  <input
                    {...register("contactNumber")}
                    onChange={e => setValue("contactNumber", e.target.value)}
                    defaultValue={contactNumberFieldValue}                  
                  />
                </label>
                <label>
                  Link
                  <input
                    {...register("link")}
                    onChange={e => setValue("link", e.target.value)}
                    defaultValue={linkFieldValue} 
                  />
                </label>                
                <div className="button-container">
                    <CustomButton 
                        butonStyle="navigation" 
                        onClick={() => props.history.goBack()} 
                        iconLeft={true} 
                        icon={Backward}
                      >
                      BACK
                    </CustomButton>   
                    <CustomButton 
                        butonStyle="navigation" 
                        iconRight={true} 
                        icon={Forward}
                        type="submit"
                      >
                      NEXT
                    </CustomButton>                           
                </div>                
            </form>
    </>
  );
}

export default EditPostingStep02Page;