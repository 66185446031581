import { CSSProperties } from 'react';
import { StylesConfig } from 'react-select';

type MyOptionType = {
  label: string;
  value: string;
};

type IsMulti = false | true;

const customControlStyles: CSSProperties = {
  border: "1px solid #D3DEEB",
  borderRadius: "8px",
  background: "#F8FBFF",
  cursor: "pointer",
  ["&:hover" as any]: {},
  ["&:active" as any]: {},
  ["&:focus" as any]: {
    border: "1px solid #A1B3C9"
  },
}

const customClearIndicator: CSSProperties = {
  color: "#0D3058",
  ["&:hover" as any]: {
    color: "#A1B3C9"
  }
}

const customMenu: CSSProperties = {
  color: "#0D3058",
  border: "1px solid #D3DEEB",
  borderRadius: "8px 8px 8px 8px",
  marginTop: "10px",
  cursor: "pointer",
  position: "absolute",
  width: "100%",
  zIndex: 1000,
  backgroundColor: "#F8FBFF"
}

const customDropdownIndicator: CSSProperties = {
  color: "#0D3058",
  ["&:hover" as any]: {
    color: "#C2D2E4"
  }
}

const customPlaceholder: CSSProperties = {
  color: "#0D3058"
}

const customNoOptionsMessage: CSSProperties = {
  color: "0D3058"
}

const customMultiValue: CSSProperties = {
  backgroundColor: "#EAEDF4"
}

const customMultiValueLabel: CSSProperties = {
  color: "0D3058"
}

const customMultiValueRemove: CSSProperties = {
  backgroundColor: "",
  ["&:hover" as any]: {
    backgroundColor: "#0D3058",
    color: "#EAEDF4"
  }  
}

const customSingleValue: CSSProperties = {
  backgroundColor: "",
  color: "#0D3058",
  ["&:hover" as any]: {
    color: "#0D3058"
  }
}

const customStyles: StylesConfig<MyOptionType, IsMulti> = {
  control: (provided, state) => ({
    ...provided,
    ...customControlStyles
  }), 
  clearIndicator: (provided, state) => ({
    ...provided,
    ...customClearIndicator
  }),
  menu: (provided, state) => ({
    ...customMenu
  }), 
  dropdownIndicator: (provided, state) => ({
    ...provided,
    ...customDropdownIndicator
  }), 
  indicatorSeparator: (provided, state) => ({
  }),
  placeholder: (provided, state) => ({
    ...provided,
    ...customPlaceholder
  }),
  noOptionsMessage:(provided, state) => ({
    ...provided,
    ...customNoOptionsMessage
  }),
  multiValue:(provided, state) => ({
    ...provided,
    ...customMultiValue
  }),
  multiValueLabel:(provided, state) => ({
    ...provided,
    ...customMultiValueLabel
  }),
  multiValueRemove:(provided, state) => ({
    ...provided,
    ...customMultiValueRemove
  }),
  singleValue:(provided, state) => ({
    ...provided,
    ...customSingleValue
  }),  
};

export default customStyles;