import { useState } from 'react';
import { Link } from "react-router-dom";
import {toSvg} from "jdenticon";

import { baseURL } from '../../../services/helper/axios.helper';

// Style related imports
import "./organisations.page.style.scss";

export type OrganisationsCardComponentProps = {
  history?: any;
  id?: number;
  name?: string;
  about?: string;
  contactName?: string | null;
  contactEmail?: string;
  contactNumber?: string;
  creatorId?: number;
  link?: string;
  img?: string;
  created_at?: string;
  updated_at?: string;
};


const OrganisationsCardComponent = (props: OrganisationsCardComponentProps) => { 
  const [imageLoaded, setImageLoaded] = useState(false); 

  window.jdenticon_config = {
    hues: [220],
    lightness: {
      color: [0.38, 0.8],
      grayscale: [0.3, 0.9]
    },
    saturation: {
      color: 0.44,
      grayscale: 0.47
    },
    backColor: "#a1cbe500"
  };

  const SVG = toSvg(props.name, 200);

  function createMarkup() {
    return { __html: SVG };
  }

  const imageUrl:any = (baseURL ? baseURL : "") + "/" + props.img;

  return (
    <Link to={`organisation-profile/${props.id}`}>
      <div className="organisation-card-container">
        <h3 className="organisation-card-name">{props.name}</h3>
        <div className="image-overflow-wrapper">
        <img
            className="organisation-card-image overflow-center"
            style={!imageLoaded ? { display: "none" } : {}}
            src={imageUrl}
            onLoad={() => { setImageLoaded(true);}}
            alt="Visual representation of the organisation"
        />
        </div>
        { !imageLoaded ? <div dangerouslySetInnerHTML={createMarkup()} /> : null }
      </div>       
   </Link>
  );
}

export default OrganisationsCardComponent;