import { RouteProps } from 'react-router';
import { useEffect, useState } from 'react';
import Card from '../../../components/post-card/post-card.component';
import moment from "moment";
import ApiService from '../../../../services/api/api.service';

import { useAppDispatch } from '../../../../store/hooks';

import { ReactComponent as ZeroPosts } from "../../../../assets/empty-inbox.svg";


import "./all-postings-list.style.scss";

import { show, hide } from '../../../../store/slices/loading/loading.slice';


interface PostingsType {
  id: number;
  title: string;
  organisation: string;
  type: {
    name: string;
  };
  datePosted: string;
  published: string;
  description: string;
}

export type AllPublishedPostingsListPageProps = {
  history?: any;  
} & RouteProps;


const AllPublishedPostingsListPage = (props: AllPublishedPostingsListPageProps) => { 
  const [postings, setPostings] = useState<PostingsType[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    
    dispatch(show());
    ApiService.getPublishedPosts()
    .then((posts:any) => {
      setPostings(posts.data);
      dispatch(hide());
    })
    .catch(() => {
      dispatch(hide());
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <div className="heading-container">
        <h2 className="heading-counter">{postings.length}</h2>
        <h2 className="heading-text">POSTS TOTAL</h2>
      </div>
      <div className="fade-container">
          {postings.map(post => (
              <Card
                id={post.id}
                title={post.title}
                key={post.id}
                organisation={post.organisation}
                type={post.type.name}
                description={post.description.slice(0, 200).concat("...")}
                datePosted={moment(post.datePosted).format("LLLL")}
                published={post.published ? "PUBLISHED" : "PENDING"}
              />
            ))}
        {postings.length === 0 && (
          <div className="image-center">
            <ZeroPosts />
          </div>
        )}      
        <div className="bottom-page-space"></div>      
      </div>
    </>
  );
}

export default AllPublishedPostingsListPage;