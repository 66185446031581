import moment from "moment";


class DashboardSponsorHelper {
  
  getPublishedPosts = (allPosts: any, organisationId: number) => {
    return allPosts.filter((post:any) => (post.published === true && post.organisation_id === organisationId)).length;
  }

  getForReviewPostrs = (allPosts: any, organisationId: number) => {
    return allPosts.filter((post:any) => (post.published !== true && post.organisation_id === organisationId)).length;
  }

  getAllPosts = (allPosts: any) => {
    if(allPosts) {
      return allPosts.length;
    } else {
      return 0;
    }
  }

  greetingText = () => {
    const currentHour = moment()
      .local()
      .hour();
    if (currentHour >= 12 && currentHour <= 17) return "kia ora,";
    else if (currentHour >= 18) return "pō mārie,";
    else return "ata mārie,";
  };

}

export default new DashboardSponsorHelper();