import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Select from "react-select";
import {toSvg} from "jdenticon";

// Imported assets
import { ReactComponent as ProgressBarTwo } from "../../../assets/progress-bar-2.svg";
import { ReactComponent as ProgressBarTwoMobile } from "../../../assets/progress-bar-2-mobile.svg";
import { ReactComponent as Error } from "../../../assets/error.svg";
import { ReactComponent as Upload } from "../../../assets/upload.svg";
import { ReactComponent as ChooseFile } from "../../../assets/choose-file.svg";
import { ReactComponent as Cancel } from "../../../assets/cancel-image.svg";

import customStyles from "./register-select.style";
import Forward from "../../../assets/next.png";
import Backward from "../../../assets/back.png";

// Style related imports
import "./register.page.style.scss";


// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import helper from './register.page.helper';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';
import { setFetchFromStore, setOrganisationsLibrary, openExistingOrgTab, closeExistingOrgTab, setRegistrationDataInStore } from '../../../store/slices/registration/registration.slice';
import { RegistrationModel } from '../../../store/slices/registration/registration.slice.model';

// Props type
export type RegisterStep02PageProps = {
  history?: any;  
} & any;

//===== MAIN COMPONENT =====
const RegisterStep02Page = (props: RegisterStep02PageProps) => {
  
  // useStates
  const [orgTab, setOrgTab] = useState("create");
  const [uploadDeactivated, setUploadDeactivated] = useState(true);
  const [selectedFile, setSelectedFile] = useState<any>({name: ""});
  const [file, setFile] = useState<any>(null);
  const [organisationsForSelect, setOrganisationsForSelect] = useState<any>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState({selectedOrganisationOption: []});

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, formState: {errors}} = useForm({mode: "onTouched", reValidateMode: 'onChange'});
  const { getDataFromServer, registrationStarted, organisations, registrationData, openExistingOrganisationTab } = useAppSelector(state => state.registration);

  let registrationModelObject: RegistrationModel = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    c_password: "",
    isCreator: false,
    organisation_id: 0,
    img: {},
    organisationName: "",
    about: "",
    contactEmail: "",
    contactNumber: "",
    role_id: 0,
    link: ""
  };

  // Call to action methods
  const onSubmit = (data:any) => {    

    if(openExistingOrganisationTab) {
      registrationModelObject.organisation_id = (Array.isArray(data.selectedOrganisation) ? data.selectedOrganisation[0].value : data.selectedOrganisation.value);
      registrationModelObject.about = registrationData.about;
      registrationModelObject.img = registrationData.img;
      registrationModelObject.organisationName = registrationData.organisationName;
    } else {
      registrationModelObject.organisationName = data.organisationName;
      registrationModelObject.img = selectedFile;
      registrationModelObject.about = data.aboutOrganisation;
      registrationModelObject.link = data.link;
    }

    console.log(registrationData);

    registrationModelObject.firstName = data.firstName;
    registrationModelObject.lastName = data.lastName;    
    registrationModelObject.isCreator = registrationData.isCreator;
    registrationModelObject.contactEmail = registrationData.contactEmail;
    registrationModelObject.contactNumber = data.contactNumber;

    registrationModelObject.email = registrationData.email;
    registrationModelObject.password = registrationData.password;
    registrationModelObject.c_password = registrationData.c_password;

    dispatch(setRegistrationDataInStore(registrationModelObject));
    props.history.push(`/register-step03`);
  }

  const onChangeHandler = (event:any) => {
    const type =
      event.target.files[0] !== undefined ? event.target.files[0].type : null;
    if (type === "image/jpeg" || type === "image/png") {
      setSelectedFile(event.target.files[0]);
      setFile(URL.createObjectURL(event.target.files[0]));
      setUploadDeactivated(true);
      // setIsUploadFile(true);
    } else if (type === null) {
      return null;
    } else {
      alert("please upload either a .png or .jpeg");
    }
  };

  const handleOrganisationChange = (selectedOrganisationOption:any) => {
    setValue("selectedOrganisation", selectedOrganisationOption);
    setSelectedOrganisation({ selectedOrganisationOption });
  }

  window.jdenticon_config = {
    hues: [220],
    lightness: {
      color: [0.38, 0.8],
      grayscale: [0.3, 0.9]
    },
    saturation: {
      color: 0.44,
      grayscale: 0.47
    },
    backColor: "#a1cbe500"
  };

  const SVG = toSvg(props.name, 100);

  function createMarkup() {
    return { __html: SVG };
  }

  const clearFile = () => {
    setSelectedFile(null);
    setFile(null);
    setUploadDeactivated(false);
  };

  useEffect(() => {
    
    if(openExistingOrganisationTab) {

      let selectedOrganisationOption:any = helper.getSelectedOrganisation(organisations, registrationData.organisation_id);  
      setValue("selectedOrganisation", selectedOrganisationOption);
      setSelectedOrganisation(() => ({selectedOrganisationOption}));

      setValue("firstName", registrationData.firstName);
      setValue("lastName", registrationData.lastName);
      setValue("contactNumber", registrationData.contactNumber);

      setOrgTab("existing");
    } else {
      
      if(registrationData.img) {
        setSelectedFile(registrationData.img);
        const binaryData = [];
        binaryData.push(registrationData.img);
        setFile(URL.createObjectURL(new Blob(binaryData, {type: "application/zip"})));
        setUploadDeactivated(true);
        // setIsUploadFile(true);  
      } else {
        clearFile();
      }      

      setValue("firstName", registrationData.firstName);
      setValue("lastName", registrationData.lastName);
      setValue("contactNumber", registrationData.contactNumber);
      setValue("link", registrationData.link);
      setValue("aboutOrganisation", registrationData.about);
      setValue("organisationName", registrationData.organisationName);

      setOrgTab("create");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps              
  },[openExistingOrganisationTab]);

  useEffect(() => {

    if(!registrationStarted) {
      props.history.push(`/register-step01`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[registrationStarted]);

  useEffect(() => {
    
    if(getDataFromServer) {
      clearFile();
      dispatch(show());            
      ApiService.getOrganisations()
      .then((org) => {        
        setOrganisationsForSelect(helper.getOrganisationsForSelect(org.data));
        dispatch(setOrganisationsLibrary(helper.getOrganisationsForSelect(org.data)));
        dispatch(setFetchFromStore());
        dispatch(hide());            
      })
      .catch(() => {
        dispatch(hide());            
      });  
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[]);

  useEffect(() => {
    
    if(!getDataFromServer) {        
      setOrganisationsForSelect(organisations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[getDataFromServer])

  return (
    <>
      <div className="heading-container">
        <h2 className="heading-text">Register - Add Details</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">

            <div className="progress-bar-container">
              {window.innerWidth > 400 ? (
                <ProgressBarTwo width="100%" />
              ) : (
                <ProgressBarTwoMobile width="75%" />
              )}
            </div>

            <div className="organisation-buttons">
              <div
                onClick={() => dispatch(closeExistingOrgTab())}
                className={
                  orgTab === "create"
                    ? "organisation-button left selected"
                    : "organisation-button left"
                }
              >
                <p>Create New Organisation</p>
              </div>
              <div
                onClick={() => dispatch(openExistingOrgTab())}
                className={
                  orgTab === "existing"
                    ? "organisation-button right selected"
                    : "organisation-button right"
                }
              >
                <p>Add To Existing Organisation</p>
              </div>
            </div>

            {orgTab === "create" && (
                <label>
                  Profile Image
                  <div
                    className={
                      !uploadDeactivated
                        ? "image-container-activated"
                        : "image-container-deactivated"
                    }
                  >
                    <input
                      type="file"
                      className="form-control"
                      onChange={onChangeHandler}
                      disabled={uploadDeactivated}
                    />
                    {file && (
                      <div className="preview-image-container">
                        {selectedFile.name !== "icon" ? (
                          <img src={file} className="preview-image" alt="logo" />
                        ) : (
                          <div dangerouslySetInnerHTML={createMarkup()} />
                        )}
                      </div>
                    )}
                    {!file && (
                      <>
                        <div className="upload-item">
                          <Upload />
                          <p className="image-subheading">DRAG FILE TO UPLOAD</p>
                        </div>
                        <div className="choosefile-item">
                          <ChooseFile />
                          <p className="image-subheading">CHOOSE FILE</p>
                        </div>
                      </>
                    )}
                    <div className="file-name-item">
                      {selectedFile ? (
                        <>
                          <p>
                            {selectedFile &&
                              (selectedFile.name === "icon"
                                ? "Generated Avatar"
                                : selectedFile.name)}
                          </p>
                          <button
                            type="button"
                            className="delete-file-button"
                            onClick={() => clearFile()}
                          >
                            <Cancel />
                          </button>
                        </>
                      ) : (
                        <p className="upload-image-text">
                          Select an image or leave empty to generate a unique avatar
                        </p>
                      )}
                    </div>
                  </div>
                </label>
              )}

              {orgTab === "existing" && (
                <label>
                  Organisation Name
                  <Select
                    {...register("selectedOrganisation", {required: true })}
                    styles={customStyles}                            
                    options={organisationsForSelect}
                    onChange={handleOrganisationChange}
                    value={selectedOrganisation.selectedOrganisationOption}
                    placeholder="Select an organisation"
                    theme={theme => helper.selectedTheme(theme)}
                  />
                  {errors.selectedOrganisation && <p className="error-message">Oganisation name is required<Error /></p>}
                </label>
              )}

              {orgTab === "create" && (
                <>
                  <label>
                    Organisation Name
                    <input
                        {...register("organisationName", { required: true, minLength: 3 })}                      
                    />
                    {errors.organisationName && (
                      <p className="error-message">
                        Add organisation name
                        <Error />
                      </p>
                    )}
                  </label>
                  <label>
                    About Organisation
                    <textarea
                      {...register("aboutOrganisation", { required: true, minLength: 3 })}       
                    />
                    {errors.aboutOrganisation && (
                      <p className="error-message">
                        Add description about your organisation
                        <Error />
                      </p>
                    )}
                  </label>
                </>
              )}

              {/* {orgTab === "existing" && (
                <label>
                  About Organisation
                  <textarea
                    {...register("aboutOrganisation", { required: true, minLength: 3 })}  
                    readOnly
                  />
                </label>
              )} */}

              <label>
                First Name
                <input
                  {...register("firstName", { required: true, minLength: 3 })}                  
                />
                {errors.firstName && (
                  <p className="error-message">
                    Add Contact Persons First Name
                    <Error />
                  </p>
                )}
              </label>

              <label>
                Last Name
                <input
                  {...register("lastName", { required: true, minLength: 3 })} 
                />
                {errors.lastName && (
                  <p className="error-message">
                    Add Contact Persons Name
                    <Error />
                  </p>
                )}
              </label>

              <label>
                Contact Number
                <input
                  {...register("contactNumber", { required: true, minLength: 3 })} 
                />
                {errors.contactNumber && (
                  <p className="error-message">
                    Add contact persons number
                    <Error />
                  </p>
                )}
              </label>              


              {orgTab === "create" && (
                <label>
                  Link
                  <input
                    {...register("link", { required: true, minLength: 3 })}                    
                  />
                  {errors.link && (
                    <p className="error-message">
                      Add a link to organisation profile
                      <Error />
                    </p>
                  )}
                </label>
              )}

            <div className="button-container">
                  <CustomButton 
                      butonStyle="navigation" 
                      onClick={() => props.history.goBack()} 
                      iconLeft={true} 
                      icon={Backward}
                    >
                    BACK
                  </CustomButton>   
                  <CustomButton 
                      butonStyle="navigation" 
                      iconRight={true} 
                      icon={Forward}
                      type="submit"
                    >
                    NEXT
                  </CustomButton>     
            </div>

      </form>
    </>
  )
}

export default RegisterStep02Page;

