import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Select from "react-select";
import moment from "moment";

// Imported assets
import { ReactComponent as ProgressBarOne } from "../../../../assets/progress-bar-1.svg";
import { ReactComponent as ProgressBarOneMobile } from "../../../../assets/progress-bar-1-mobile.svg";
import Forward from "../../../../assets/next.png";
import Backward from "../../../../assets/back.png";

// Style related imports
import "./create-posting.style.scss";
import customStyles from "./create-posting-select.style";

// Custom components and helper imports
import CustomButton from '../../../components/custom-button/custom-button.component';
import helper from './create-posting-select.helpers';

// Service and redux imports
import ApiService from '../../../../services/api/api.service';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {show, hide} from '../../../../store/slices/loading/loading.slice';
import { setFetchFromStore, setPostInStore, setDepartmentsLibrary, setLevelsLibrary, setIdentitiesLibrary, setTypesLibrary, setOrganisationsLibrary, openExistingOrgTab } from '../../../../store/slices/edit-post/edit-post.slice';
import { PostModel } from '../../../../store/slices/edit-post/edit-post.slice.model';

// Types and interfaces
export type CreatePostingStep01PageProps = {
  history?: any;  
} & any;

//===== MAIN COMPONENT =====
const CreatePostingStep01Page = (props: CreatePostingStep01PageProps) => { 

  // useStates
  const [typesForSelect, setTypesForSelect] = useState<any>([]);
  const [selectedType, setSelectedType] = useState({selectedTypeOption: []});
  const [levelsForSelect, setLevelsForSelect] = useState<any>([]);
  const [selectedLevel, setSelectedLevel] = useState({selectedLevelOption: []});
  const [departmentsForSelect, setDepartmentsForSelect] = useState<any>([]);
  const [selectedDepartment, setSelectedDepartment] = useState({selectedDepartmentOption: []});
  const [identitiesForSelect, setIdentitiesForSelect] = useState<any>([]);
  const [selectedIdentities, setSelectedIdentities] = useState({selectedIdentitiesOption: []});

  let postModelObject:PostModel = {
    id:60,
    authorId: 2,
    title: "",
    description: "",
    img: "",
    link: "",
    keyDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
    isSent: 0,
    published: true,
    about: "",
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    organisation_id: 0,
    organisation: "",
    type: [],
    departments: [],
    identities: [],
    levels: []
}  
  
  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { getDataFromServer, departments, identities, levels, types, editPost } = useAppSelector(state => state.editPost);
  const profile = useAppSelector(state => state.login.profile);
  const { handleSubmit, setValue } = useForm();
  
  const goBack = () => {
    props.history.goBack();
  }

  const handleTypeChange = (selectedTypeOption:any) => {
    setValue("selectedType", selectedTypeOption);
    setSelectedType({ selectedTypeOption });
  };

  const handleLevelsChange=(selectedLevelOption:any) => {
    setValue("selectedLevel", selectedLevelOption);
    setSelectedLevel({ selectedLevelOption });
  };

  const handleDepartmentsChange = (selectedDepartmentOption:any) => {
    setValue("selectedDepartment", selectedDepartmentOption);
    setSelectedDepartment({ selectedDepartmentOption });
  };

  const handleIdentitiesChange = (selectedIdentitiesOption:any) => {
    setValue("selectedIdentities", selectedIdentitiesOption);
    setSelectedIdentities({ selectedIdentitiesOption });
  };

  const onSubmit = (data:any) => {
    let postModelObjectForSubmit:PostModel = {
      id:0,
      authorId: 2,
      title: "",
      description: "",
      img: "",
      link: "",
      keyDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      isSent: 0,
      published: false,
      about: "",
      contactName: "",
      contactEmail: "",
      contactNumber: "",
      organisation_id: 0,
      organisation: "",
      type: [],
      departments: [],
      identities: [],
      levels: []
    }

    postModelObjectForSubmit.id = editPost.id;
    postModelObjectForSubmit.authorId = editPost.authorId;
    postModelObjectForSubmit.title = editPost.title;
    postModelObjectForSubmit.description = editPost.description;
    postModelObjectForSubmit.img = editPost.img;
    postModelObjectForSubmit.link = editPost.link;
    postModelObjectForSubmit.keyDate = editPost.keyDate;
    postModelObjectForSubmit.isSent = editPost.isSent;
    postModelObjectForSubmit.published = editPost.published;
    postModelObjectForSubmit.contactName = editPost.contactName;
    postModelObjectForSubmit.contactEmail = editPost.contactEmail;
    postModelObjectForSubmit.contactNumber = editPost.contactNumber;
    postModelObjectForSubmit.organisation_id = editPost.organisation_id;
    postModelObjectForSubmit.organisation = editPost.organisation;
    postModelObjectForSubmit.about = editPost.about;

    if("selectedDepartment" in data) {
      postModelObjectForSubmit.departments = data.selectedDepartment;    
    } else {
      postModelObjectForSubmit.departments = editPost.departments;
    }

    if("selectedIdentities" in data) {
      postModelObjectForSubmit.identities = data.selectedIdentities;    
    } else {
      postModelObjectForSubmit.identities = editPost.identities;
    }

    if("selectedLevel" in data) {
      postModelObjectForSubmit.levels = data.selectedLevel;    
    } else {
      postModelObjectForSubmit.levels = editPost.levels;    
    }

    if("selectedType" in data) {
      postModelObjectForSubmit.type = data.selectedType;    
    } else {
      postModelObjectForSubmit.type = editPost.type;    
    }

    dispatch(setPostInStore(postModelObjectForSubmit));
    props.history.push('/create-post/step-2');
  };

  useEffect(() => {
    if(getDataFromServer) {

      dispatch(show());
      ApiService.getAllLibraries()
        .then((library) => {
          
          dispatch(setDepartmentsLibrary(helper.getDepartmentsForSelect(library.data)));
          dispatch(setLevelsLibrary(helper.getLevelsForSelect(library.data)));
          dispatch(setIdentitiesLibrary(helper.getIdentitiesForSelect(library.data)));
          dispatch(setTypesLibrary(helper.getTypesForSelect(library.data)));

          dispatch(setPostInStore(postModelObject));
          dispatch(setFetchFromStore());

          ApiService.getOrganisations()
              .then((organisations) => {
                console.log(organisations);
                dispatch(setOrganisationsLibrary(helper.getOrganisationsForSelect(organisations.data)));
                dispatch(hide());            
              })
              .catch(() => {
                dispatch(hide());            
              });          
        })
        .catch((error) => {
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[]);

  useEffect(() => {

    if(!getDataFromServer) {
      setDepartmentsForSelect(departments);
      setLevelsForSelect(levels);
      setIdentitiesForSelect(identities);
      setTypesForSelect(types);

      let selectedTypeOption:any = editPost.type;            
      setSelectedType(() => ({selectedTypeOption}));

      let selectedLevelOption:any = editPost.levels;            
      setSelectedLevel(() => ({selectedLevelOption}));

      let selectedDepartmentOption:any = editPost.departments;         
      setSelectedDepartment(() => ({selectedDepartmentOption}));

      let selectedIdentitiesOption:any = editPost.identities;           
      setSelectedIdentities(() => ({selectedIdentitiesOption}));

      if(profile.roleId === 4) {
        dispatch(openExistingOrgTab());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getDataFromServer]);


  return (
    <>
          <div className="heading-container">
            <h2 className="heading-text">CREATE A POST</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">
              <div className="progress-bar-container">
                {window.innerWidth > 400 ? (
                  <ProgressBarOne width="100%" />
                ) : (
                  <ProgressBarOneMobile width="75%" />
                )}
              </div>
              <p className="create-paragraph">
                Review details and skip to the next step
              </p>
              <label>
                Opportunity type
                <Select
                  name="selectedType"
                  styles={customStyles}                        
                  options={typesForSelect}
                  onChange={handleTypeChange}
                  value={selectedType.selectedTypeOption}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#D3DEEB",
                      primary: "#A1B3C9",
                      neutral0: "#0D3058",
                      primary50: "#A1B3C9"
                    }
                  })}
                />
              </label>
              <label>
                Who does this apply to?
                <Select
                  name="selectedLevel"
                  styles={customStyles}                  
                  placeholder="Select one or many"                  
                  options={levelsForSelect}
                  onChange={handleLevelsChange}
                  value={selectedLevel.selectedLevelOption}
                  isMulti
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#A1B3C9",
                      primary: "#A1B3C9",
                      neutral0: "#0D3058",
                      primary50: "#A1B3C9"
                    }
                  })}
                />
              </label>
              <label>
                What department/students does this belong to?             
                <Select
                  name="selectedDepartment"
                  styles={customStyles}                  
                  placeholder="Select one or many"
                  options={departmentsForSelect}
                  onChange={handleDepartmentsChange}
                  value={selectedDepartment.selectedDepartmentOption}
                  isMulti
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#A1B3C9",
                      primary: "#A1B3C9",
                      neutral0: "#0D3058",
                      primary50: "#A1B3C9"
                    }
                  })}
                />
              </label>
              <label>
                What identities does this apply to?
                <Select
                  name="selectedIdentities"
                  styles={customStyles}                  
                  placeholder="Select one or many"
                  options={identitiesForSelect}
                  onChange={handleIdentitiesChange}
                  value={selectedIdentities.selectedIdentitiesOption}
                  isMulti
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#A1B3C9",
                      primary: "#A1B3C9",
                      neutral0: "#0D3058",
                      primary50: "#A1B3C9"
                    }
                  })}
                />
              </label>                    
              <div className="button-container">
                  <CustomButton 
                      butonStyle="navigation" 
                      onClick={() => goBack()} 
                      iconLeft={true} 
                      icon={Backward}
                    >
                    BACK
                  </CustomButton>   
                  <CustomButton 
                      butonStyle="navigation" 
                      iconRight={true} 
                      icon={Forward}
                      type="submit"
                    >
                    NEXT
                  </CustomButton>                           
              </div>
          </form>
    </>
  );
}

export default CreatePostingStep01Page;