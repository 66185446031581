import * as yup from 'yup';

export const confirmPasswordValidationForm = yup.object().shape({
  email: yup
      .string()
      .required("Email is required")
      .email("Not a valid email"),
  password: yup
      .string()
      .required("Password is required")
      .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .test('passwords-match', 'Passwords must match', function (value) { return this.parent.password === value })
      //.oneOf([Yup.ref('password'), null], 'Passwords must match')
})