import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginSliceModel } from './login.slice.model'
import { AppInitialState } from '../../app.initial.state';
import axios from '../../../services/helper/axios.helper';
import { StorageService as storage } from "../../../services/storage/storage.service";

const initialState:LoginSliceModel = AppInitialState.login;

const requestHeader = (token: string):any => {
  const config: any = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  config.headers['Authorization'] = `Bearer ${token}`;

  return config;
}

export const fetchUser = createAsyncThunk(
  'login/fetchUser', 
  async (_, { getState }) => {
    
    const state = getState() as any;
    const { token } = state.login;

    try { 
      const response:any | null = await axios.get("/api/details-stakeholder", requestHeader(token));
      
      return response.data;
    }
    catch (error) {

    }    
  })

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    recoverPassword: (state) => {
      state.error = null;
      state.isRecoveredPassword = false;
      state.isRecoveringPassword = true;
    },
    recoverPasswordSuccess: (state) => {
      
    },
    stayLoggedInOn: (state) => {
      state.isStayLoggedIn = true;
      storage.setItem('ptSLI', 'YES');
    },
    stayLoggedInOff: (state) => {
      state.isStayLoggedIn = false;
      storage.removeItem('ptSLI');
    },    
    login: (state) => {
      state.error = null;
      state.isLoggedIn = false;
      state.isLoggingIn = true;
    },
    checking: (state) => {
      state.error = null;
      state.isChecking = true;
    },
    loginSuccess: (state, action:PayloadAction<any>) => {      
      const token = action.payload.success.token;
      
      storage.setItem('ptToken', token);
      storage.setItem('ptProfile', JSON.stringify(action.payload.success));
      state.token = token;
      state.profile = action.payload.success;
      state.isLoggedIn = true;
      state.isLoggingIn = false;
    },
    userCheckSuccess: (state, action:PayloadAction<any>) => {
      storage.setItem('ptProfile', JSON.stringify(action.payload.success));
      
      state.token = storage.getItem('ptToken');
      state.profile = action.payload.success;
      state.isLoggedIn = true;
      state.isChecking = false;
      state.isLoggingIn = false;
    },
    userCheckFail: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isChecking = false;
    },
    loginFail: (state, action: PayloadAction<any>) => {
      
      state.error = action.payload;
      state.isLoggedIn = false;
      state.isLoggingIn = false;      
    },
    logOut: (state) => {
      state.token = null;
      state.isStayLoggedIn = false;
      state.error = null;
      state.isLoggedIn = false;
      state.isLoggingIn = false;
      state.isRecoveredPassword  = false;
      state.isRecoveringPassword = false;
      state.profile  = null;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUser.pending, (state) => {
      
    })
    .addCase(fetchUser.fulfilled, (state, action) => {
      if(action.payload) {
        state.profile = action.payload.success;
      } else {
        state.profile = null;
      }      
    })
  }
})

export const { recoverPassword, recoverPasswordSuccess, login, loginSuccess, loginFail, stayLoggedInOn, stayLoggedInOff, logOut, userCheckSuccess, checking, userCheckFail } = LoginSlice.actions;

export default LoginSlice.reducer;