import moment from "moment";

class EditPostingHelper {
  getTypesForSelect = (data:any) => {
    const typesArray = data.types.map((type:any) => (
        {
          value: type.id,
          label: type.name
        }
      ));
    return typesArray;
  }

  getSelectedType = (data:any):any => {
    return {label: data.type.name, value: data.type.id}
  }

  getLevelsForSelect = (data:any) => {
    const levelsArray = data.levels.map((level:any) => (
      {
        value: level.id,
        label: level.name
      }
    ));      
    return levelsArray;
  }

  getSelectedLevels = (data:any) => {
    const levelsArray = data.levels.map((level:any) => (
      {
        value: level.id,
        label: level.name
      }
    ));  

    return levelsArray;
    //return {label: data.levels.name, value: data.levels.name}
  }

  getDepartmentsForSelect = (data:any) => {
    const departmentsArray = data.departments.map((department:any) => (
      {
        value: department.id,
        label: department.name
      }
    ));    
    return departmentsArray;
  }

  getSelectedDepartments = (data:any) => {
    const departmentsArray = data.departments.map((department:any) => (
      {
        value: department.id,
        label: department.name
      }
    ));  
    return departmentsArray;
  }

  getIdentitiesForSelect = (data:any) => {
    const identitiesArray = data.identities.map((identity:any) => (
      {
        value: identity.id,
        label: identity.name
      }
    ));    
    return identitiesArray;
  }

  getSelectedIdentities = (data:any) => {
    const identitiesArray = data.identities.map((identity:any) => (
      {
        value: identity.id,
        label: identity.name
      }
    ));  
    return identitiesArray;
  }

  returnIds = (data:any) => {    
    let departmentIdArray:any = [];
    
    data.map((item:any) => {
      departmentIdArray.push(item.value)
      return null;
    });
    return departmentIdArray;
  }

  returnDepartmentItems = (data:any) => {    
    const departmentArray = data.departments.map((department:any) => ({
        name: department.label
    }));
    return departmentArray;
  }
  
  returnLevelItems = (data:any) => {
    const levelArray = data.levels.map((level:any) => ({
      name: level.label
    }));
    return levelArray;
  }
  
  returnIdentityItems = (data:any) => {
    const identitiesArray = data.identities.map((identity:any) => ({
      name: identity.label
    }));
    return identitiesArray;
  }
  
  returnTypeItems = (data:any) => {
    return [
      {name: data.type.label}
    ]
  }
  
  returnPublishInfo = (data:any) => {
    const isPublished = data.published ? "Yes" : "No";

    return [
      {name: isPublished}
    ]
  }
  
  returnPeopleInterestedNum = (data:any) => {
    return [
      {name: 'None right now, but check back soon!'}
    ]
  }
  
  returnViewNum = (data:any) => {
    return [
      {name: 'None right now, but check back soon!'}
    ]
  }    

  returnPostDetails = (data:any) => {

    return {
      postTitle : data.title,
      organisation: data.organisation,
      description:  data.description,
      datePosted: moment(data.keyDate).format("dddd, MMMM Do, h:mm a (YYYY)"),
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      contactNumber: data.contactNumber,
      contactLink: data.link,
      icon: "http://pungao-torohu-api.test/storage/types/June2020/BJixYcGyrDIlVKENehYw.png"
    }
  }
   
}

export default new EditPostingHelper();