import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/pūngao-torohū-logo-small.svg";

import { useAppSelector } from '../../../store/hooks';

import HeaderAdmin from './header-admin.component';
import HeaderStakeholder from './header-stakeholder.component';
import HeaderSponsor from './header-sponsor.component';

import "./header.component.style.scss";


interface HeaderProps { 
  history?: any;
}

const Header = (props: HeaderProps) => {
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn);
  const profile = useAppSelector(state => state.login.profile);
  
  if(isLoggedIn) {
    if(profile.roleId === 4) {
      return  <>
          <HeaderStakeholder history={props.history} />
          <Link to="/" className="small-logo-corner">
              <Logo />
          </Link> 
        </>
    } else if(profile.roleId === 6) {
          return  <>
          <HeaderSponsor history={props.history} />
          <Link to="/" className="small-logo-corner">
              <Logo />
          </Link> 
        </>       
    } else {
      return  <>
          <HeaderAdmin history={props.history} />
          <Link to="/" className="small-logo-corner">
              <Logo />
          </Link> 
        </>  
    }
  } else {
    return null;
  }
}

export default Header;