import axios from '../helper/axios.helper';
import { StorageService as storage } from "../storage/storage.service";

class AuthService { 

  config(removeToken:boolean = false) {
    const token = storage.getItem("ptToken");

    if(removeToken){
      storage.removeItem("ptToken")
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    };

    return config;
  }

  configNoToken(){
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return config;
  }

  postLogin(email: string, password: string) {

    const loginData = new FormData();    

    loginData.append("email", email);
    loginData.append("password", password);

    return axios.post("/api/login", loginData, this.configNoToken());
  }

  postFirstLogin(email: string, password: string) {

    const loginData = new FormData();

    loginData.append("email", JSON.parse(email));
    loginData.append("password", JSON.parse(password));
    
    return axios.post("/api/login", loginData, this.configNoToken());
  }  

  postChangePassword(email: string, currentPassword:string, newPassword: string, confirmPassword: string) {
    const loginData = new FormData();

    loginData.append("currentPassword", currentPassword);
    loginData.append("password", newPassword);
    loginData.append("c_password", confirmPassword);
    loginData.append("email", email);

    return axios.post("/api/users/change-password", loginData, this.config());
  }

  postSendResetLink(email: string) {
    const postData = new FormData();

    postData.append("email", email);

    return axios.post("/api/password/email", postData, this.configNoToken());
  }

  postForgetPassword(email: string, newPassword: string, confirmPassword: string, token: string) {
    const postData = new FormData();

    postData.append("email", email);
    postData.append("token", token);
    postData.append("password", newPassword);
    postData.append("password_confirmation", confirmPassword);

    return axios.post("/api/password/reset", postData, this.configNoToken());
  }


  getLogout() {
    return axios.get("/api/logout", this.config(true));
  };  

  getUserDetails() {
    return axios.get("/api/details-stakeholder", this.config());
  }

}

export default new AuthService();