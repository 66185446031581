import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch} from '../../../store/hooks';
import AuthService from '../../../services/auth/auth.service';
import {show, hide } from '../../../store/slices/loading/loading.slice';
import { logOut } from '../../../store/slices/login/login.slice';
import { setFetchFromServer } from '../../../store/slices/edit-post/edit-post.slice';

interface HeaderStakeholderProps { 
  history?: any;
}

const HeaderStakeholder = (props: HeaderStakeholderProps) => {
  const [checked, setChecked] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showListHelp, setShowListHelp] = useState(false);
  
  const dispatch = useAppDispatch();

  const checkHandler = (menuItem:string) => {
    
    if(menuItem === 'create-posting') {
      dispatch(setFetchFromServer());
    }

    setChecked(!checked);
  };

  const logoutHanlder = () => {
    dispatch(show());
    AuthService.getLogout().then(() => {
      dispatch(logOut());
      dispatch(hide());
      props.history.push('/login');
    })
  }

  return (
    <>
      <div
        className={checked ? "screen-mask" : "screen-mask-none"}
        onClick={() => checkHandler("none")}
      ></div>
      <div className="nav-container">
          <div className="checkbox-wrapper">
            <input type="checkbox" checked={checked} onChange={() => checkHandler("none")} />
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div id={checked ? "menu-checked" : "menu-unchecked"}>

          <div className="item-container">
            <div className="nav-item" onClick={() => checkHandler("none")}>
              <NavLink to="/dashboard-stakeholder" exact className="normal" activeClassName="active">
                HOME
              </NavLink>
            </div>
            <div className="nav-item" onClick={() => checkHandler("create-posting")}>
              <NavLink
                to="/create-post/step-1"
                className="normal"
                activeClassName="active"
              >
                CREATE NEW POSTING
              </NavLink>
            </div>
            <div className="postings-container">
              <div
                className="postings-heading"
                onClick={() => setShowList(!showList)}
              >
                <p className="postings-text">POSTINGS</p>

                {showList ? (
                  <p className="postings-asset">–</p>
                ) : (
                    <p className="postings-asset">+</p>
                  )}
              </div>
              <div
                style={showList ? { maxHeight: "500px" } : {}}
                className="postings-list-container"
              >
                <div
                  className="pending-item"
                  onClick={showList ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/postings/published"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    APPROVED POSTINGS
                  </NavLink>
                </div>
                <div
                  className="pending-item"
                  onClick={showList ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/postings/pending"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    PENDING POSTINGS
                  </NavLink>
                </div>
                <div
                  className="pending-item"
                  onClick={showList ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/postings/everything"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    ALL POSTINGS
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="postings-container">
              <div
                className="postings-heading"
                onClick={() => setShowListHelp(!showListHelp)}
              >
                <p className="postings-text">HELP</p>

                {showListHelp ? (
                  <p className="postings-asset">–</p>
                ) : (
                    <p className="postings-asset">+</p>
                  )}
              </div>
              <div
                style={showListHelp ? { maxHeight: "500px" } : {}}
                className="postings-list-container"
              >
                <div
                  className="pending-item"
                  onClick={showListHelp ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/about"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    ABOUT
                  </NavLink>
                </div>
                <div
                  className="pending-item"
                  onClick={showListHelp ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/help/getting-started-list"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    GETTING STARTED
                  </NavLink>
                </div>
                <div
                  className="pending-item"
                  onClick={showListHelp ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/help/FAQ-list"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    FAQ
                  </NavLink>
                </div>
                <div
                  className="pending-item"
                  onClick={showListHelp ? () => checkHandler("none") : () => { }}
                >
                  <NavLink
                    to="/help/contact"
                    className="pending-normal"
                    activeClassName="pending-active"
                  >
                    CONTACT
                  </NavLink>
                </div>
                <div 
                      className="pending-item" 
                      onClick={showListHelp ? () => checkHandler("none") : () => { }}
                    >
                    <NavLink to="/change-password" className="normal" activeClassName="active">
                      CHANGE PASSWORD
                    </NavLink>
                </div>  
              </div>
            </div>
            <div className="nav-item-logout" onClick={logoutHanlder}>
              <div>LOGOUT</div>
            </div>
          </div>

          </div>
        

      </div>
    </>
  );
}

export default HeaderStakeholder;