import React from "react";
import "../../pages/dashboard/admin/dashboard-admin.style.scss";
import Internal from "../../../assets/internal.png";

interface DashboardCardComponentProps { 
  title: string;
  className: string;
  number: number;
  onClick: Function;
}

const DashboardCardComponent = (props: DashboardCardComponentProps) => {
  return (
    <div className={props.className} onClick={() => props.onClick()}>
      {props.className !== "no-hover" && (
        <img src={Internal} alt="" className="card-internal-link" />
      )}
      <h1 className="dashboard-number">{props.number ? props.number : 0}</h1>
      <p className="dashboard-card-heading">{props.title}</p>
    </div>
  );
}

export default DashboardCardComponent;