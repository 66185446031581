import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RegistrationModel, RegistrationSliceModel } from './registration.slice.model'
import { AppInitialState } from '../../app.initial.state';

const initialState: RegistrationSliceModel = AppInitialState.registration;

export const RegistrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    openExistingOrgTab: (state) => {
      state.openExistingOrganisationTab = true;
    },
    closeExistingOrgTab: (state) => {
      state.openExistingOrganisationTab = false;
    },
    startRegistration: (state) => {
      state.registrationStarted = true;
    },
    doneRegistration: (state) => {
      state.registrationStarted = false;
    },
    setFetchFromServer: (state) => {
      state.getDataFromServer = true;
    },
    setFetchFromStore: (state) => {
      state.getDataFromServer = false;
    },
    setIsEmailConfirmed: (state) => {
      state.isEmailConfirmed = true;
    },
    setIsEmailUnConfirmed: (state) => {
      state.isEmailConfirmed = false;
    },
    setRegistrationDataInStore: (state, action:PayloadAction<RegistrationModel>) => {     
      state.registrationData = action.payload;
    },
    setOrganisationsLibrary: (state, action:PayloadAction<any[]>) => {
      state.organisations = action.payload;
    },    
  }
});

export const {setFetchFromServer, setFetchFromStore, setRegistrationDataInStore, setOrganisationsLibrary, openExistingOrgTab, closeExistingOrgTab, startRegistration, doneRegistration, setIsEmailConfirmed, setIsEmailUnConfirmed } = RegistrationSlice.actions;

export default RegistrationSlice.reducer;