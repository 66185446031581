import createImage1 from "../../../assets/create-image-1.png";
import createImage2 from "../../../assets/create-image-2.png";
import createImage3 from "../../../assets/create-image-3.png";
import createImage4 from "../../../assets/create-image-4.png";
import createImage5 from "../../../assets/create-image-5.png";
import createImage6 from "../../../assets/create-image-6.png";
import createImage7 from "../../../assets/create-image-7.png";

import "./help.page.style.scss";

const CreatePostHelpPage = () => {
  return (
    <div className="getting-started-container">
      <div className="heading-container">
          <h2 className="heading-text">CREATE A POST</h2>
      </div>
      <div className="getting-started-item-container">
        <div className="getting-started-image-top">
          <img src={createImage1} alt="Create a post" />
        </div>
        <ul>
          <li className="getting-started-list">
            To create a post, click on the menu icon on the top left corner, and
            click on “Create New Posting”.
          </li>
          <li className="getting-started-list">
            There are three steps to creating a new post. The progress bar at
            the top of the page indicates your current stage.
          </li>
          <li className="getting-started-list">
            Complete the form, and click next to progress to the next stage. If
            an error prompt appears, read the message to find out how to fix it.
            This usually appears if the input was left blank.
          </li>
          <div className="getting-started-image">
            <img src={createImage2} alt="Create a post select options" />
          </div>
          <li className="getting-started-list">
            Be specific when selecting options. This helps your post to be
            targeted towards the most relevant student groups. For the student
            level and department type fields, you can select multiple options.
          </li>
          <div className="getting-started-image">
            <img
              src={createImage3}
              alt="Create a post back and forward options"
            />
          </div>
          <li className="getting-started-list">
            Press the back button to move to previous stages of the form.
          </li>
          <li className="getting-started-list">
            If you would like to change any of your selections for the drop down
            inputs, hover your mouse over the selected option, and click on the
            “x” icon to remove that option. Likewise with the text fields,
            simply click on the text, and tap the “back” or “delete” button on
            your keyboard to remove any text.
          </li>
          <div className="getting-started-image">
            <img
              src={createImage4}
              alt="Create a post description field"
            />
          </div>
          <li className="getting-started-list">
            For the “Description” field, be specific and add any relevant
            information for the student. This text is shared with the student.
          </li>
          <li className="getting-started-list">
            The “Deadline date and time” field calendar functionality for the
            student app. They can save posts to their native calendar. This is
            particularly helpful in the case of events and application
            deadlines.
          </li>
          <div className="getting-started-image">
            <img
              src={createImage5}
              alt="Create a post contact details"
            />
          </div>
          <li className="getting-started-list">
            Add up to date contact information. This information will be shared
            with the student.
          </li>
          <div className="getting-started-image">
            <img
              src={createImage6}
              alt="Create a post preview form part 1"
            />
          </div>
          <div className="getting-started-image-top">
            <img
              src={createImage7}
              alt="Create a post preview form part 2"
            />
          </div>
          <li className="getting-started-list">
            At the end of the form, preview all the details you have entered, if
            you would like to edit and make changes, simply click on the “edit”
            form button. Otherwise, submit the form. A submission successful
            prompt will display. You can then navigate to the home screen to
            view your dashboard. There you will see a new post that is pending.
            This post is pending approval from one of our administrators.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CreatePostHelpPage;
