import { ReactNode } from "react";

import "./custom-button.component.style.scss";

type CustomButtonComponentProps = { 
  onClick?:any;
  butonStyle: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  icon?:string;
  type?: "button" | "submit" | "reset";
  children?: ReactNode,
  style?:any
}

const CustomButtonComponent = (props: CustomButtonComponentProps) => {
  const theClassName = 'buttons-style '+ props.butonStyle;
  const typeValue = props.type || "button";

  return (
    <button      
      onClick={props.onClick}
      className={theClassName}
      type={typeValue}
      style={props.style}
    >
      {props.iconLeft && (
        <img src={props.icon} className="icon-left" alt="icon" />
      )}
      <p className="button-text">
        {props.children}
      </p>      
      {props.iconRight && (
        <img src={props.icon} className="icon-right" alt="icon" />
      )}      
    </button>
  );
}

export default CustomButtonComponent;