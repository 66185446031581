interface StudentLoginErrorPageProps { 
  history: any;
}

const StudentLoginErrorPage = (props : StudentLoginErrorPageProps) => {

  return (
    <div>
      Error Student Login
    </div>
  )
}

export default StudentLoginErrorPage;