import { Link } from "react-router-dom";

import "./post-card.component.style.scss";

export interface PostCardComponentProps { 
  id: number;
  title: string;
  organisation: string;
  type: string;
  datePosted: string;
  published: string;
  description: string;
}

const PostCardComponent = (props: PostCardComponentProps) => {
  return (
    <div className="posting-card-container">
      <Link to={`/post/${props.id}`}>
        <h3 className="posting-card-title">{props.title}</h3>
        <h3 className="posting-card-title">{props.organisation}</h3>
        <p className="posting-card-type">{props.type}</p>
        <p className="posting-card-date">{props.datePosted}</p>
        <p className="posting-card-published">{props.published}</p>
        <p className="posting-card-description">{props.description}</p>
      </Link>
    </div>
  );
}

export default PostCardComponent;