import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {toSvg} from "jdenticon";


// Imported assets
import { ReactComponent as ProgressBarThree } from "../../../assets/progress-bar-3.svg";
import { ReactComponent as ProgressBarThreeMobile } from "../../../assets/progress-bar-3-mobile.svg";
import Forward from "../../../assets/next_.png";
import Backward from "../../../assets/back.png";

// Style related imports
import "./create-user.page.style.scss";

// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import { baseURL } from '../../../services/helper/axios.helper';
import helper from './create-user-select.helpers';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';

export type CreateUserStep03PageProps = {
  history?: any;  
} & any;

const CreateUserStep03Page = (props: CreateUserStep03PageProps) => { 
  
    // Usestates
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [aboutField, setAboutField] = useState("");
    const [linkField, setLinkField] = useState("");

    // Other hooks initilisation
    const dispatch = useAppDispatch();
    const { handleSubmit } = useForm();
    const { registrationStarted, organisations, registrationData, openExistingOrganisationTab } = useAppSelector(state => state.registration);
    const profile = useAppSelector(state => state.login.profile);

    window.jdenticon_config = {
      hues: [220],
      lightness: {
        color: [0.38, 0.8],
        grayscale: [0.3, 0.9]
      },
      saturation: {
        color: 0.44,
        grayscale: 0.47
      },
      backColor: "#a1cbe500"
    };
  
    const SVG = toSvg(props.name, 200);
  
    function createMarkup() {
      return { __html: SVG };
    }


    const onSubmit = (data:any) => {
        const formData = new FormData();


        if(profile.roleId === 1) {
            if(openExistingOrganisationTab) { 
              formData.append("organisation_id", JSON.stringify(registrationData.organisation_id));
              formData.append("isCreator", "0");
            } else {
              formData.append("img", registrationData.img);
              formData.append("organisationName", registrationData.organisationName);
              formData.append("about", registrationData.about);
              formData.append("contactName", registrationData.firstName + " " + registrationData.lastName);
              formData.append("contactEmail", registrationData.contactEmail);
              formData.append("contactNumber", registrationData.contactNumber);
              formData.append("link", registrationData.link);
              formData.append("isCreator", "1");
              formData.append("role_id", JSON.stringify(registrationData.role_id));
            }
            
            formData.append("firstName", registrationData.firstName);
            formData.append("lastName", registrationData.lastName);
            formData.append("email", registrationData.email);
            formData.append("password", registrationData.password);
            formData.append("c_password", registrationData.c_password);
        } else {
          //$input = $request->only(['firstName', 'lastName', 'email', 'img', 'isJdenticon', 'name', 'role_id', 'gender_id']);
          formData.append("firstName", registrationData.firstName);
          formData.append("lastName", registrationData.lastName);
          formData.append("email", registrationData.email);
          formData.append("role_id", "4");
          formData.append("isCreator", "0");
          formData.append("organisation_id", profile.organisation.id);          
        }

        dispatch(show());
        ApiService.postRegisterUserByAdmin(formData)
            .then(() => {
              console.log('Registration sumitted successfully.');
              dispatch(hide());
              props.history.push('/');
            })
            .catch((error) => {
              console.log(error);
              dispatch(hide());          
            });

        //props.history.push(`/register-step03`);
    }

    useEffect(() => {

      if(openExistingOrganisationTab) { 
        const selectedOrganisationOption:any = helper.getSelectedOrganisation(organisations, registrationData.organisation_id);  
  
        setSelectedFile(selectedOrganisationOption[0].imageUrl);
        setAboutField(selectedOrganisationOption[0].about);
        setLinkField(selectedOrganisationOption[0].link);
      } else {
  
        if(registrationData.img) {
          setSelectedFile(baseURL + "/" + profile.img);
        }        
        
        setAboutField(registrationData.about);
        setLinkField(registrationData.link);      
      }
            
      if(profile.roleId === 6) {
        setSelectedFile(profile.img);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[]);
  
  
    useEffect(() => {
  
      if(!registrationStarted) {
        props.history.push(`/register-step01`);
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[registrationStarted]);

    return (
        <>
          <div className="heading-container">
            <h2 className="heading-text">REGISTER A CONTRIBUTOR – REVIEW DETAILS</h2>
          </div>
          <form className="preview-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="progress-bar-container">
                {window.innerWidth > 400 ? (
                  <ProgressBarThree width="100%" />
                ) : (
                  <ProgressBarThreeMobile width="75%" />
                )}
            </div>
            <div className="preview-form-container">
                <h2 className="organisation-name">Spark</h2>
                <div className="preview-image-container">
                {selectedFile &&
                  selectedFile !== "api/profile/icon" ? (
                    <img
                      src={
                        openExistingOrganisationTab
                          ? baseURL + "/" + selectedFile
                          : ((profile.roleId === 6) ? baseURL + "/" + profile.img : URL.createObjectURL(selectedFile))
                      }
                      className="preview-image"
                      alt="logo"
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={createMarkup()} />
                  )}
                </div>

                {profile.roleId === 1 && (
                  <>
                    <p className="preview-form-title">About</p>
                    <p className="preview-form-input">{aboutField}</p>                  
                    <p className="preview-form-title">Organisation Type</p>
                    <p className="preview-form-input">{helper.getUserTypeName(registrationData.role_id)}</p>
                  </>  
                )}

                <p className="preview-form-title">First Name</p>
                <p className="preview-form-input">{registrationData.firstName}</p>
          
                <p className="preview-form-title">Last Name</p>
                <p className="preview-form-input">{registrationData.lastName}</p>

                <p className="preview-form-title">Contact Email</p>
                <p className="preview-form-input">{registrationData.email}</p>

                <p className="preview-form-title">Contact Number</p>
                <p className="preview-form-input">{registrationData.contactNumber}</p>

                <p className="preview-form-title">Link</p>
                <p className="preview-form-input">{linkField}</p>
            </div>
            <div className="bottom-register-container">
                <CustomButton 
                    butonStyle="navigation" 
                    onClick={() => props.history.goBack()}
                    iconLeft={true} 
                    icon={Backward}
                  >
                  BACK
                </CustomButton>   
                <CustomButton 
                    butonStyle="action" 
                    iconRight={true} 
                    icon={Forward}
                    type="submit"
                  >
                  REGISTER
                </CustomButton>
            </div>
          </form>
        </>
    )
    
}

export default CreateUserStep03Page;