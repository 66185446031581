import moment from "moment";


class DashboardStakeholderHelper {
  
  getPublishedPosts = (allPosts: any) => {
    return allPosts.filter((post:any) => post.published === true).length;
  }

  getForReviewPostrs = (allPosts: any) => {
    return allPosts.filter((post:any) => post.published !== true).length;
  }

  getAllPosts = (allPosts: any) => {
    if(allPosts) {
      return allPosts.length;
    } else {
      return 0;
    }
  }

  greetingText = () => {
    const currentHour = moment()
      .local()
      .hour();
    if (currentHour >= 12 && currentHour <= 17) return "kia ora,";
    else if (currentHour >= 18) return "pō mārie,";
    else return "ata mārie,";
  };


}

export default new DashboardStakeholderHelper();