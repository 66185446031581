import { useState, useEffect } from 'react';
import {toSvg} from "jdenticon";
import { Link } from "react-router-dom";

// Imported assets
import { ReactComponent as ArrowForward } from "../../../../assets/arrow_forward.svg";
import { ReactComponent as Plant } from "../../../../assets/dashboard-plant.svg";

// Style related imports
import "./dashboard-sponsor.page.scss";

// Service and redux imports
import ApiService from '../../../../services/api/api.service';
import {useAppSelector, useAppDispatch} from '../../../../store/hooks';
import {show, hide} from '../../../../store/slices/loading/loading.slice';
import {fetchPost, fetchPostSuccess, fetchPostFail, fetchTypeSuccess} from '../../../../store/slices/post/post.slice';

// Custom components and helper imports
import Card from '../../../components/dashboard-card/dashboard-card.component';
import Accordion from "../../../components/accordion/accordion.component";
import { baseURL } from '../../../../services/helper/axios.helper';
import helper from './dashboard-sponsor.helper';

export type DashboardSponsorPageProps = { 
  history: any;
} & any;

const DashboardSponsorPage = (props : DashboardSponsorPageProps) => {

  // Usestates
  const [imageLoaded, setImageLoaded] = useState(false);
  const [numFavorites, setNumFavorites] = useState(0);
  const [numPostPublished, setNumPostPublished] = useState(0);
  const [numPostToReview, setNumPostToReview]  = useState(0);

  window.jdenticon_config = {
    hues: [220],
    lightness: {
      color: [0.38, 0.8],
      grayscale: [0.3, 0.9]
    },
    saturation: {
      color: 0.44,
      grayscale: 0.47
    },
    backColor: "#a1cbe500"
  };

  const SVG = toSvg(props.name, 200);

  function createMarkup() {
    return { __html: SVG };
  }  

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.login.profile);
  const isFetchingPost = useAppSelector(state => state.post.isFetchingPost);

  const imageUrl:any = baseURL + "/" + profile.img;


useEffect(() => {
    if(!isFetchingPost) {
      dispatch(fetchPost());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(isFetchingPost) {
      dispatch(show());
      ApiService.getAllPosts()
      .then((post) => {
        console.log(profile.organisation.id);
        setNumPostPublished(helper.getPublishedPosts(post.data, profile.organisation.id));
        setNumPostToReview(helper.getForReviewPostrs(post.data, profile.organisation.id));
        // setNumPost(helper.getAllPosts(post.data));

        dispatch(fetchPostSuccess(post.data));

        ApiService.getPostTypes()
        .then((postType) => {
          dispatch(fetchTypeSuccess(postType.data));

          ApiService.getFavorites()
          .then((favorites:any) => {
              setNumFavorites(favorites.length)
              dispatch(hide());
          })
          .catch(() => {
            dispatch(hide());
          });

        })
        .catch(() => {
          dispatch(hide());
        })

      })
      .catch((error) => {
        dispatch(fetchPostFail(error));
        dispatch(hide());
      })
    } 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isFetchingPost]);

  return (
    <div className="dashboard-container">
      
      <div className="dashboard-text-container">
          <div className="dashboard-heading-container">
            <h1 className="dashboard-heading">{helper.greetingText()}</h1>
            <p className="dashboard-name">
              {profile.firstName} {profile.lastName}
            </p>
            <p className="dashboard-organisation">
              {profile.organisation.name}
            </p>
          </div>
          <div className="plant-container">
            <Plant style={{ zIndex: -20 }} />
          </div>
      </div>

      <div className="wrapper">

        <Accordion title="PROFILE">
            <div className="dashboard-profile-container">

                  <div className="dashboard-profile-header">
                      <p className="dashboard-profile-organisation">
                        {profile.organisation.name}
                      </p>
                      <button
                        className="dashboard-edit"
                        onClick={() => props.history.push("/edit-profile-stakeholder")}
                      >
                        EDIT
                      </button>                    
                  </div>

                  <div className="organisation-profile-image-placeholder">
                        <img
                          className="organisation-card-image"
                          style={!imageLoaded ? { display: "none" } : {width: "200px"}}
                          src={imageUrl}
                          onLoad={() => setImageLoaded(true)}
                          alt="Visual representation"
                        />
                        { !imageLoaded ? <div className="image-jdenticon" dangerouslySetInnerHTML={createMarkup()} /> : null }
                  </div>   

                  <label className="profile-label">About</label>
                  <p className="dashboard-profile-text">
                    {profile.organisation.about}
                  </p>

                  <label className="profile-label">Contact Name</label>
                  <p className="dashboard-profile-text">
                    {profile.firstName} {profile.lastName}
                  </p>

                  <label className="profile-label">Contact Email</label>
                  <p className="dashboard-profile-text">
                    {profile.organisation.contactEmail}
                  </p>

                  <label className="profile-label">Contact Number</label>
                  <p className="dashboard-profile-text">
                    {profile.organisation.contactNumber}
                  </p>

             </div>

          </Accordion>
      </div>

      <div className="dashboard-card-container-sponsor">

          <Card
              onClick={() => props.history.push("/postings/pending")}
              number={numPostToReview}
              title="pending posts"
              className="dashboard-card-sponsor"
            />

          <Card
            onClick={() => props.history.push("/postings/published")}
            number={numPostPublished}
            title="published posts"
            className="dashboard-card-sponsor"
          />    

          <Card
            onClick={() => {}}
            number={0}
            title="STUDENTS HAVE SAVED YOUR POSTS"
            className="no-hover-sponsor"
          />

          <Card
            onClick={() => {}}
            number={numFavorites}
            title="STUDENTS HAVE VIEWED YOUR POSTS"
            className="no-hover-sponsor"
          />
    

      </div>
      
      <div className="view-all-container">
          <Link to={"/postings/everything"} className="dashboard-button">
            <p className="dashboard-button-text">
              VIEW ALL OTHER PUBLISHED POSTINGS
            </p>
            <ArrowForward />
          </Link>
      </div>

      <div className="space-below" />

    </div>
  )
}

export default DashboardSponsorPage;