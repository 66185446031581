import { useState, useEffect } from 'react';

// Imported assets
import Delete from "../../../../assets/close_.png";
import Back from "../../../../assets/back.png";
import Publish from "../../../../assets/publish_.png";
import { ReactComponent as ProgressBarThree } from "../../../../assets/progress-bar-3.svg";
import { ReactComponent as ProgressBarThreeMobile } from "../../../../assets/progress-bar-3-mobile.svg";

// Style related imports
import "../detail/post-detail.style.scss";

// Custom components and helper imports
import helper from './create-posting-select.helpers';
import CustomButton from '../../../components/custom-button/custom-button.component';
import CustomWidget from '../../../components/custom-widget/custom-widget.component';
import PostDetailComponent from '../detail/post-detail.component';
import CustomPrompt from '../../../components/prompts/custom-prompt.component';

// Service and redux imports
import ApiService from '../../../../services/api/api.service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {show, hide} from '../../../../store/slices/loading/loading.slice';

export type CreatePostingStep03PageProps = {
  history?: any;  
} & any;

const CreatePostingStep03Page = (props: CreatePostingStep03PageProps) => { 

  // Usestates
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [confirmMessage, setConfirmMessage ] = useState("CONTINUE TO MAKE IT VISIBLE TO STUDENTS.");
  
  // Other hooks initilisation  
  const { editPost, openExistingOrganisationTab } = useAppSelector(state => state.editPost);
  const profile = useAppSelector(state => state.login.profile);
  const dispatch = useAppDispatch();

  const discardPost = () => {
    props.history.push('/');
  }
  
  const publishPost = () => {
    const formData = new FormData();

    if(!openExistingOrganisationTab) {      
      formData.append("name", editPost.organisation);
      formData.append("about", editPost.about);
    } else {
      if(profile.roleId !== 4) {
        formData.append("organisation_id", JSON.stringify(editPost.organisation_id));
      } else {
        formData.append("organisation_id", profile.organisation.id);
      }
    }
    
    formData.append("title", editPost.title);
    formData.append("description", editPost.description);
    formData.append("keyDate", editPost.keyDate);

    formData.append("contactName", editPost.contactName);
    formData.append("contactEmail", editPost.contactEmail);
    formData.append("contactNumber", editPost.contactNumber);
    formData.append("link", editPost.link);

    formData.append("type_id", editPost.type.value);
    formData.append("departments", JSON.stringify(helper.returnIds(editPost.departments)));
    formData.append("identities", JSON.stringify(helper.returnIds(editPost.identities)));
    formData.append("levels", JSON.stringify(helper.returnIds(editPost.levels)));

    
    setPublishConfirmation(false);
    dispatch(show());
    ApiService.postCreatePost(formData)
      .then(() => {
        console.log('Post Submitted')        
        dispatch(hide());
        props.history.push('/');
      })
      .catch(() => {
        console.log('Error unable to sumbit.')
        dispatch(hide());
      })
  }

  const publishInfo = helper.returnPublishInfo(editPost);
  const departmentItems = helper.returnDepartmentItems(editPost);
  const levelItems = helper.returnLevelItems(editPost);
  const identityItems = helper.returnIdentityItems(editPost);
  const typeItems = helper.returnTypeItems(editPost);  
  // const peopleInterestedNum = helper.returnPeopleInterestedNum();
  // const viewNum = helper.returnViewNum();  

  useEffect(() => {
    if(profile.roleId !== 4) {
      setConfirmMessage("CONTINUE TO MAKE IT VISIBLE TO STUDENTS.");
    } else {
      setConfirmMessage("CONTINUE TO SUBMIT FOR APPROVAL");
    }

  }, [profile]);


  return (
    <>
        {publishConfirmation && (
          <CustomPrompt
            onClick={() => publishPost()}
            closeHandler={() => setPublishConfirmation(false)}
            confirmLabelButton="CONTINUE"
            cancelLabelButton="NO, BACK TO POST"
            isWarning={false}
            promptMessage={confirmMessage}
          />
        )}
        <div className="heading-container">
          <h2 className="heading-text">Review Edit</h2>
        </div>
        <div className="view-individual-post-container">
          <div className="progress-bar-container">
            {window.innerWidth > 400 ? (
              <ProgressBarThree width="100%" />
            ) : (
              <ProgressBarThreeMobile width="75%" />
            )}
          </div>
          <div className="button-container">
              <CustomButton 
                  butonStyle="navigation" 
                  onClick={() => props.history.goBack()} 
                  iconLeft={true}
                  icon={Back}
                >
                KEEP EDITING
              </CustomButton>
          </div>
          <div className="preview-form-container">
              <PostDetailComponent {...helper.returnPostDetails(editPost)}/>
          </div>

          <CustomWidget title="Student Group/s" items={departmentItems} />
          <CustomWidget title="What Level Student/s" items={levelItems} />
          <CustomWidget title="Identities" items={identityItems} />
          <CustomWidget title="Type" items={typeItems} />
          <CustomWidget title="Published" items={publishInfo} />
          {/* <CustomWidget title="People Interested" items={peopleInterestedNum} />
          <CustomWidget title="Views" items={viewNum} />   */}

          <div className="button-container">         
            <CustomButton 
                butonStyle="warning" 
                onClick={() => discardPost()}
                iconRight={true} 
                icon={Delete}
              >
              DISCARD
            </CustomButton>
            <CustomButton 
                butonStyle="action-green" 
                onClick={() => setPublishConfirmation(true)}
                iconRight={true} 
                icon={Publish}
              >
              PUBLISH
            </CustomButton>            
          </div>

        </div>
    </>
  );
}

export default CreatePostingStep03Page;