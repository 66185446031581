
type ContactCardProps = {
  history: any;
} & any;

const ContactCard = (props: ContactCardProps) => {
  return (
    <div className="contact-card-container">
      <h3>{props.heading}</h3>
      <div className="help-image-container">
        <div className="help-image">
          <img className="help-image" src={props.image} alt={props.image} />
        </div>
      </div>
      <p>{props.name}</p>
      <a href={props.mailtoemail}>
        <p className="email-style">{props.email}</p>
      </a>
      <p>{props.phone}</p>
    </div>
  );
};

export default ContactCard;
