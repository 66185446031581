import { useEffect, useState } from 'react';
import {toSvg} from "jdenticon";

// Imported assets
import Back from "../../../assets/back.png";
import Edit from "../../../assets/edit.png";

// Style related imports
import "./organisation-profile.page.style.scss";

// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import { baseURL } from '../../../services/helper/axios.helper';

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';


export type OrganisationProfilePageProps = {
  history?: any;  
} & any;

const OrganisationProfilePage = (props: OrganisationProfilePageProps) => { 
  const [organisationProfile, setOrganisationProfile] = useState<any>({});
  const [imageLoaded, setImageLoaded] = useState(false); 

  window.jdenticon_config = {
    hues: [220],
    lightness: {
      color: [0.38, 0.8],
      grayscale: [0.3, 0.9]
    },
    saturation: {
      color: 0.44,
      grayscale: 0.47
    },
    backColor: "#a1cbe500"
  };

  const SVG = toSvg(props.name, 100);

  function createMarkup() {
    return { __html: SVG };
  }

  const organisationId = props.match.params.id;

  const dispatch = useAppDispatch();

  useEffect(() => {

    dispatch(show());

    ApiService.getOrganisationById(organisationId)
      .then((organisation:any) => {
        setOrganisationProfile(organisation.data);
        dispatch(hide());
      })
      .catch((error) => {
        dispatch(hide());
      })
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const imageUrl:any = (baseURL ? baseURL : "") + "/" + (organisationProfile.img ? organisationProfile.img : "storage/users/default_organisation.jpg");

  return (
    <>
      <div className="organisation-profile-top-margin">
          <div className="heading-container">
            <h2 className="heading-text">{organisationProfile.name}</h2>
          </div>
          <div className="button-container">
              <CustomButton 
                  butonStyle="action" 
                  onClick={() => props.history.push(`/edit-organisation-profile/${organisationId}`)} 
                  iconLeft={true}
                  icon={Edit}
                >
                EDIT
              </CustomButton>
          </div>
          <div className="organisation-profile-container">
            
            <div className="organisation-profile-image-placeholder">
              <img
                  className="organisation-card-image"
                  style={!imageLoaded ? { display: "none" } : {width: "200px"}}
                  src={imageUrl}
                  onLoad={() => setImageLoaded(true)}
                  alt="Visual representation of the organisation"
              />
              { !imageLoaded ? <div dangerouslySetInnerHTML={createMarkup()} /> : null }
            </div>

            <label className="profile-label">About</label>
            <p className="profile-text">{organisationProfile.about}</p>

            <label className="profile-label">Contact Name</label>
            <p className="profile-text">{organisationProfile.contactName}</p>

            <label className="profile-label">Contact Email</label>
            <p className="profile-text">{organisationProfile.contactEmail}</p>

            <label className="profile-label">Contact Number</label>
            <p className="profile-text">{organisationProfile.contactNumber}</p>
          </div>
          <div className="button-container">
          <CustomButton 
                  butonStyle="navigation" 
                  onClick={() => props.history.push('/organisations')} 
                  iconLeft={true}
                  icon={Back}
                >
                Back
              </CustomButton>
          </div>
      </div>    
    </>
  );
}

export default OrganisationProfilePage;