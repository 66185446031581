import { useEffect, useState } from 'react';

import { ReactComponent as Plant } from "../../../../assets/dashboard-plant.svg";
import "./dashboard-admin.style.scss";

import { VictoryPie } from "victory";

import Card from '../../../components/dashboard-card/dashboard-card.component';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {show, hide } from '../../../../store/slices/loading/loading.slice';
import {fetchPost, fetchPostSuccess, fetchPostFail, fetchTypeSuccess} from '../../../../store/slices/post/post.slice';

import ApiService from '../../../../services/api/api.service';

interface DashboardAdminPageProps { 
  history: any;
}

const DashboardAdminPage = (props : DashboardAdminPageProps) => {

  const profile = useAppSelector(state => state.login.profile);
  const dispatch = useAppDispatch();
  const isFetchingPost = useAppSelector(state => state.post.isFetchingPost);

  const [numPostPublished, setNumPostPublished] = useState(0);
  const [numPostToReview, setNumPostToReview]  = useState(0);
  const [numPost, setNumPost] = useState(0);
  const [graphData, setGraphData] = useState([
      {"x":"A","y":1},
      {"x":"B","y":1},
  ]);
  const [numFavorites, setNumFavorites] = useState(0);

  const getPublishedPosts = (allPosts: any) => {
    return allPosts.filter((post:any) => post.published === true).length;
  }

  const getForReviewPostrs = (allPosts: any) => {
    return allPosts.filter((post:any) => post.published !== true).length;
  }

  const getAllPosts = (allPosts: any) => {
    if(allPosts) {
      return allPosts.length;
    } else {
      return 0;
    }
  }

  const getTypeCount = (arr:any, type:any) => {
    return arr.filter((item:any) => item.type.name === type).length;
  }

  const getGraphData = (allPost:any, allTypes:any) => {

    const data = allTypes.map((type:any) => 
                    ({
                      x: type.name,
                      y: getTypeCount(allPost, type.name)
                    })
                 );
    setGraphData(data.filter((i:any) => i.y !== 0))
  }

  useEffect(() => {
    if(!isFetchingPost) {
      dispatch(fetchPost());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(isFetchingPost) {
      dispatch(show());
      ApiService.getAllPosts()
      .then((post) => {

        setNumPostPublished(getPublishedPosts(post.data));
        setNumPostToReview(getForReviewPostrs(post.data));
        setNumPost(getAllPosts(post.data));

        dispatch(fetchPostSuccess(post.data));

        ApiService.getPostTypes()
        .then((postType) => {
          dispatch(fetchTypeSuccess(postType.data));
          getGraphData(post.data, postType.data);          
          
          ApiService.getFavorites()
          .then((favorites:any) => {
              setNumFavorites(favorites.length)
              dispatch(hide());
          })
          .catch(() => {
            dispatch(hide());
          });

        })
        .catch(() => {
          dispatch(hide());
        })
      })
      .catch((error) => {
        dispatch(fetchPostFail(error));
        dispatch(hide());
      })
    } 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isFetchingPost]);

  return (
    <div className="dashboard-container">

        <div className="dashboard-text-container">
          <div className="dashboard-heading-container">
            <h1 className="dashboard-heading">
              ata mārie,
            </h1>
            <p className="dashboard-admin-name">
              {profile.name}
            </p>
          </div>
          <div className="admin-plant-container">
            <Plant style={{ zIndex: -20 }} />
          </div>
        </div>

        
        <div className="dashboard-card-container">
          <Card
              onClick={() => props.history.push("/postings/pending")}
              number={numPostToReview}
              title="postings to review"
              className="dashboard-card"
            />
          <Card
            onClick={() => {}}
            number={numFavorites}
            title="saved postings"
            className="no-hover"
          />
          <Card
            onClick={() => props.history.push("/postings/published")}
            number={numPostPublished}
            title="published postings"
            className="dashboard-card"
          />
        </div>

        <div className="dashboard-card-container">
          <div className="dashboard-graph-card">
            <p
              className="dashboard-card-title-overview"
              onClick={() => props.history.push("/postings/all")}
            >
              OVERVIEW
            </p>

            <VictoryPie
              height={250}
              padding={25}
              data={graphData}
              animate={{
                duration: 2000,
              }}
              colorScale={[
                "#4C6D95",
                "#6185AE",
                "#85A3C5",
                "#D4E5FA",
                "#E3F0FF",
              ]}
              style={{
                labels: {
                  fontSize: 5.5,
                  fontWeight: "bold",
                  fill: "#0D3058",
                  padding: 15,                  
                  textTransform: "uppercase",
                },
              }}
            />

            <hr className="stakeholder-divider"/>
            
            <p
              className="dashboard-card-heading-overview showCursor"
              onClick={() => props.history.push("/postings/all")}              
            >
              ({numPost} postings total)
            </p>
          </div>
        </div>
        <div className="space-below"></div>
    </div>
  )
}

export default DashboardAdminPage;