type CustomWidgetComponentProps = { 
  title: string;
  items: any;
}

const CustomWidgetComponent = (props: CustomWidgetComponentProps) => {
  return (
    <>
          <p className="preview-form-title">{props.title}</p>
          <div className="department-container">
            {
              props.items.map((item:any, index: number) => (
                <p key={index} className="department-input">
                    { item.name }
                </p>
              ))
            }
          </div>
    </>
  );
}

export default CustomWidgetComponent;