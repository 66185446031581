import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import queryString from "query-string";

// Imported assets
import { ReactComponent as Error } from "../../../assets/error.svg";
import { ReactComponent as Logo } from "../../../assets/log-in-logo.svg";
import Forward from "../../../assets/next.png";

// Style related imports
import "./change-password.page.style.scss";

// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';

// Service and redux imports
import AuthService from '../../../services/auth/auth.service';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';

export type ChangePasswordPageProps = {
  history?:any;
} & any;


const ChangePasswordPage = (props: ChangePasswordPageProps) => {


  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, formState: {errors}} = useForm({mode: "onTouched", reValidateMode: 'onChange'});
  const profile = useAppSelector(state => state.login.profile);

  const values = queryString.parse(props.location.search);

  // Call to action methods
  const changePassword = (data:any) => {
    
    const currentPassword = data.currentPassword.replace(/\"/g, "");
    const newPassword = data.password;
    const confirmPassword = data.confirmPassword;
    const email = profile.email;
    
    dispatch(show());
    AuthService.postChangePassword(email, currentPassword, newPassword, confirmPassword)
      .then(() => {
        dispatch(hide());
        props.history.push("/");
      })
      .catch(() => {
        dispatch(hide());
      });
  }


  useEffect(()=> {
    console.log('helsldkfjsdf');
    if("currentPassword" in values) {
      setValue('currentPassword', values.currentPassword);
    }
  }, []);
  return (
    <>
      <form className="log-in-form" onSubmit={handleSubmit(changePassword)}>
        <div className="log-in-logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <h2 className="log-in-heading">CHANGE PASSWORD</h2>

        <label>
          Current Password
          <input
            className="create-log-in-input"
            {...register("currentPassword", {required: true, minLength:3 })}
            type="password"
          />
          {errors.currentPassword && (
            <p className="error-message-login">
              Invalid password <Error />
            </p>
          )}
        </label>

        <label>
          New Password
          <input
            className="create-log-in-input"
            {...register("password", {required: true, minLength:3 })}
            type="password"            
          />
          {errors.password && (
            <p className="error-message-login">
              Invalid password <Error />
            </p>
          )}
        </label>

        <label>
          Re-enter your new Password
          <input
            className="create-log-in-input"
            {...register("confirmPassword", {required: true, minLength:3 })}
            type="password"            
          />
          {errors.repassword && (
            <p className="error-message-login">
              Your passwords do not match <Error />
            </p>
          )}
        </label>
        <div className="login-button-container">
          <CustomButton 
              butonStyle="navigation"               
              iconLeft={true} 
              icon={Forward}
              type="submit"
          >
            CHANGE PASSWORD
          </CustomButton>  
        </div>
      </form>    
    </>
  )
}

export default ChangePasswordPage;
