import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EditPostSliceModel, PostModel, ArrayValueLabelType } from './edit-post.slice.model'
import { AppInitialState } from '../../app.initial.state';

const initialState: EditPostSliceModel = AppInitialState.editPost;

export const EditPostSlice = createSlice({
  name: 'editPost',
  initialState,
  reducers: {
    openExistingOrgTab: (state) => {
      state.openExistingOrganisationTab = true;
    },
    closeExistingOrgTab: (state) => {
      state.openExistingOrganisationTab = false;
    },    
    setFetchFromServer: (state) => {
      state.getDataFromServer = true;
    },
    setFirstTimeOnPage2: (state) => {
      state.firstTimeOnPage2 = false;
    },
    setFetchFromStore: (state) => {
      state.getDataFromServer = false;
    },
    setPostInStore: (state, action:PayloadAction<PostModel>) => {
      state.editPost = action.payload;
    },
    setDepartmentsLibrary: (state, action:PayloadAction<any[]>) => {
      state.departments = action.payload;
    },
    setLevelsLibrary: (state, action:PayloadAction<ArrayValueLabelType[]>) => {
      state.levels = action.payload;
    },
    setIdentitiesLibrary: (state, action:PayloadAction<ArrayValueLabelType[]>) => {
      state.identities = action.payload;
    },   
    setTypesLibrary: (state, action:PayloadAction<ArrayValueLabelType[]>) => {
      state.types = action.payload;
    },
    setOrganisationsLibrary: (state, action:PayloadAction<ArrayValueLabelType[]>) => {
      state.organisations = action.payload;
    },    
  }
});

export const {setFetchFromServer, setFetchFromStore, setPostInStore, setDepartmentsLibrary, setLevelsLibrary, setIdentitiesLibrary, setTypesLibrary, setOrganisationsLibrary, openExistingOrgTab, closeExistingOrgTab, setFirstTimeOnPage2 } = EditPostSlice.actions;

export default EditPostSlice.reducer;