
class RegisterPageHelper {
  selectedTheme = (theme:any) => {
    return ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#A1B3C9",
        primary: "#A1B3C9",
        neutral0: "#0D3058",
        primary50: "#A1B3C9"
      }
    })
  }

  getOrganisationsForSelect = (data:any) => {
    const organisationArray = data.map((organisation:any) => (
        {
          value: organisation.id,
          label: organisation.name,
          imageUrl: organisation.img,
          about: organisation.about,
          link: organisation.link
        }
      ));
      return organisationArray;
  }

  getSelectedOrganisation = (data:any, id:any) => {    
    return data.filter((dataItem:any) => dataItem.value === id).map((item:any) => ({
      value: item.value,
      label: item.label,
      imageUrl: item.imageUrl,
      about: item.about,
      link: item.link
    }));
  }
  
}

export default new RegisterPageHelper();