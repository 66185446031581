import { useAppSelector } from '../../../store/hooks';
import { Redirect, RouteProps } from 'react-router';
import { useLocation } from "react-router-dom";

export type HomePageProps = {
  history: any;
} & RouteProps;

const HomePage = (props : HomePageProps) => {

  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn);
  const profile = useAppSelector(state => state.login.profile);
  
  const loc = useLocation();

  if(isLoggedIn) {
    switch(profile.roleId){
      case 6:
        return <Redirect to={{ pathname: "/dashboard-sponsor", state: loc.state}} />
      case 4:
        return <Redirect to={{ pathname: "/dashboard-stakeholder", state: loc.state}} />
      case 3:
        return <Redirect to={{ pathname: "/student-login-error", state: loc.state}} />          
      case 1:
        return <Redirect to={{ pathname: "/dashboard-admin", state: loc.state}} />
      default:
        break;
    }
  } else {
    return <Redirect to={{ pathname: "/splash", state: loc.state}} />
  }

  return (
    <></>
  )
}

export default HomePage;