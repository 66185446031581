import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import {toSvg} from "jdenticon";

// Imported assets
import { ReactComponent as Error } from "../../../assets/error.svg";
import { ReactComponent as Cancel } from "../../../assets/cancel.svg";
import { ReactComponent as Upload } from "../../../assets/upload.svg";
import { ReactComponent as ChooseFile } from "../../../assets/choose-file.svg";
import Publish from "../../../assets/publish_.png";
import Back from "../../../assets/back.png";

// Style related imports
import "./edit-organisation-profile.page.style.scss";

// Service and redux imports
import ApiService from '../../../services/api/api.service';
import {useAppDispatch} from '../../../store/hooks';
import {show, hide} from '../../../store/slices/loading/loading.slice';

// Custom components and helper imports
import CustomButton from '../../components/custom-button/custom-button.component';
import { baseURL } from '../../../services/helper/axios.helper';

export type EditOrganisationProfileProps = {
  history?: any;  
} & any;


const EditOrganisationProfile = (props: EditOrganisationProfileProps) => {
  const organisationId = props.match.params.id;

  // useStates
  const [uploadDeactivated, setUploadDeactivated] = useState(true);
  const [selectedFile, setSelectedFile] = useState<any>({name: ""});
  const [file, setFile] = useState<any>(null);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [organisationProfile, setOrganisationProfile] = useState<any>({
    name: "", 
    about: "", 
    contactName: "", 
    conatactEmail: "",
    contactNumber: "", 
    link: "",
    img: ""
  });

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, formState: {errors}} = useForm();

  // Other methods
  const onSubmit = (data:any) => {
    const formData = new FormData();

    if(data.organisationName){
      formData.append("name", data.organisationName);
    }

    if(data.aboutOrganisation) {
      formData.append("about", data.aboutOrganisation);
    }

    if(data.contactName) {
      formData.append("contactName", data.contactName);
    }

    if(data.contactEmail) {
      formData.append("contactEmail", data.contactEmail);
    }

    if(data.contactNumber) {
      formData.append("contactNumber", data.contactNumber);
    }

    if(data.link) {
      formData.append("link", data.link);
    }

    if(isUploadFile && selectedFile) {
      formData.append("img", selectedFile);
    }

    dispatch(show());
    ApiService.postUpdateOrganisation(formData, organisationId)
        .then((data) => {          
          dispatch(hide());
          props.history.push(`/organisations`);
        })
        .catch((error) => {
          console.log(error);
          dispatch(hide());
        })

  };

  window.jdenticon_config = {
    hues: [220],
    lightness: {
      color: [0.38, 0.8],
      grayscale: [0.3, 0.9]
    },
    saturation: {
      color: 0.44,
      grayscale: 0.47
    },
    backColor: "#a1cbe500"
  };

  const SVG = toSvg(props.name, 100);

  function createMarkup() {
    return { __html: SVG };
  }

  const onChangeHandler = (event:any) => {
    const type =
      event.target.files[0] !== undefined ? event.target.files[0].type : null;
    if (type === "image/jpeg" || type === "image/png") {
      setSelectedFile(event.target.files[0]);
      setFile(URL.createObjectURL(event.target.files[0]));
      setUploadDeactivated(true);
      setIsUploadFile(true);
    } else if (type === null) {
      return null;
    } else {
      alert("please upload either a .png or .jpeg");
    }
  };

  const clearFile = () => {
    setSelectedFile(null);
    setFile(null);
    setUploadDeactivated(false);
  };

  // Use Effect Hooks
  useEffect(() => {

    dispatch(show());
    ApiService.getOrganisationById(organisationId)
          .then((organisation:any) => {

              const imageFile = organisation.data.img;

              setOrganisationProfile(organisation.data)
              setFile(baseURL + "/" + organisation.data.img);
              setSelectedFile({
                name: imageFile.substring(imageFile.lastIndexOf("/") + 1)
              });
              
              dispatch(hide());
          })
          .catch(() => {
              dispatch(hide());
          })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])

  return (
    <>
          <div className="heading-container">
            <h2 className="heading-text">EDIT ORGANISATION PROFILE</h2>
          </div>
          <div className="container">
              <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">
                  <p className="create-paragraph">                      
                      Update organisation details
                  </p>
                  <div className="fade-container">
                      <label>
                        Profile Image
                        <div
                          className={
                            !uploadDeactivated
                              ? "image-container-activated"
                              : "image-container-deactivated"
                          }
                        >
                          <input
                            type="file"
                            className="form-control"
                            onChange={onChangeHandler}
                            disabled={uploadDeactivated}
                          />
                          {file && (
                            <div className="preview-image-container">
                              {selectedFile.name !== "icon" ? (
                                <img src={file} className="preview-image" alt="logo" />
                              ) : (
                                <div dangerouslySetInnerHTML={createMarkup()} />
                              )}
                            </div>
                          )}
                          {!file && (
                            <>
                              <div className="upload-item">
                                <Upload />
                                <p className="image-subheading">DRAG FILE TO UPLOAD</p>
                              </div>
                              <div className="choosefile-item">
                                <ChooseFile />
                                <p className="image-subheading">CHOOSE FILE</p>
                              </div>
                            </>
                          )}
                          <div className="file-name-item">
                            {selectedFile ? (
                              <>
                                <p>
                                  {selectedFile &&
                                    (selectedFile.name === "icon"
                                      ? "Generated Avatar"
                                      : selectedFile.name)}
                                </p>
                                <button
                                  type="button"
                                  className="delete-file-button"
                                  onClick={() => clearFile()}
                                >
                                  <Cancel />
                                </button>
                              </>
                            ) : (
                              <p>
                                Select another image or leave empty to generate a unique
                                avatar
                              </p>
                            )}
                          </div>
                        </div>
                      </label>
                  </div>
                  <label>
                    Organisation Name
                    <input
                      {...register("organisationName")}
                      onChange={e => setValue("organisationName", e.target.value)}
                      defaultValue={organisationProfile.name}
                    />
                    {errors.organisationName && (
                      <p className="error-message">
                        <Error />
                        Add organisation name
                      </p>
                    )}
                  </label>

                  <label>
                    About Organisation
                    <textarea
                      {...register("aboutOrganisation")}
                      onChange={e => setValue("aboutOrganisation", e.target.value)}
                      defaultValue={organisationProfile.about}
                    />
                    {errors.aboutOrganisation && (
                      <p className="error-message">
                        Add description about your organisation
                        <Error />
                      </p>
                    )}
                  </label>

                  <label>
                    Contact Name
                    <input
                      {...register("contactName")}
                      onChange={e => setValue("contactName", e.target.value)}
                      defaultValue={organisationProfile.contactName}
                    />
                    {errors.contactName && (
                      <p className="error-message">
                        Add contact persons name
                        <Error />
                      </p>
                    )}
                  </label>

                  <label>
                    Contact Email
                    <input
                      {...register("contactEmail")}
                      onChange={e => setValue("contactEmail", e.target.value)}
                      defaultValue={organisationProfile.contactEmail}
                    />
                    {errors.contactEmail && (
                      <p className="error-message">
                        Add contact persons email
                        <Error />
                      </p>
                    )}
                  </label>

                  <label>
                    Contact Number
                    <input
                      {...register("contactNumber")}
                      onChange={e => setValue("contactName", e.target.value)}
                      defaultValue={organisationProfile.contactNumber}
                    />
                    {errors.contactNumber && (
                      <p className="error-message">
                        Add contact persons number
                        <Error />
                      </p>
                    )}
                  </label>        

                  <label>
                    Link
                    <input
                      {...register("link")}
                      onChange={e => setValue("link", e.target.value)}
                      defaultValue={organisationProfile.link}
                    />
                  </label>   

                  <div className="button-container">
                    <CustomButton 
                        butonStyle="navigation" 
                        onClick={() => props.history.push(`/organisations`)} 
                        iconLeft={true}                         
                        icon={Back}
                      >
                      CANCEL
                    </CustomButton>   
                    <CustomButton 
                        butonStyle="action-green" 
                        iconRight={true} 
                        icon={Publish}
                        type="submit"
                      >
                      Update
                    </CustomButton> 
                  </div>         

              </form>
          </div>
    </>
  );
}

export default EditOrganisationProfile;
