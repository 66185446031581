import { useEffect } from "react";
import queryString from "query-string";

import AuthService from '../../../services/auth/auth.service';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';

import { loginSuccess, loginFail } from '../../../store/slices/login/login.slice';
import {show, hide } from '../../../store/slices/loading/loading.slice';

export type FirstTimeLoginPageProps = {
  history?: any;  
} & any;


const FirstTimeLoginPage = (props: FirstTimeLoginPageProps) => {

  const values = queryString.parse(props.location.search);
  const email = JSON.stringify(values.email);
  const password = JSON.stringify(values.password);
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn);
  const dispatch = useAppDispatch();

  // const [showLoginError, setShowLoginError] = useState(false);

  useEffect(() => {
    if(!isLoggedIn) {
      dispatch(show());
      console.log(values);

      AuthService.postFirstLogin( email, password )
      .then((user) => {        
        if(user.status === 201 || user.status === 200) {
          dispatch(loginSuccess(user.data));          
          dispatch(hide());
          props.history.push(`/change-password?currentPassword=${password}`);
        } else if(user.status === 401) {
          dispatch(loginFail("Wrong username or password."));
          dispatch(hide());
        }
      })
      .catch(error => {
          // setShowLoginError(true);
          dispatch(loginFail(error));
      })
    } else {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
    </>
  );
}


export default FirstTimeLoginPage;
