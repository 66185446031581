import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

// Imported assets
import { ReactComponent as ProgressBarTwo } from "../../../../assets/progress-bar-2.svg";
import { ReactComponent as ProgressBarTwoMobile } from "../../../../assets/progress-bar-2-mobile.svg";
import { ReactComponent as Error } from "../../../../assets/error.svg";
import Forward from "../../../../assets/next.png";
import Backward from "../../../../assets/back.png";

// Style related imports
import "./create-posting.style.scss";
import "../../../../styles/datepicker.style.scss";
import customStyles from "./create-posting-select.style";

// Custom components and helper imports
import helper from './create-posting-select.helpers';
import CustomButton from '../../../components/custom-button/custom-button.component';

// Service and redux imports
import {useAppSelector, useAppDispatch} from '../../../../store/hooks';
import {PostModel} from '../../../../store/slices/edit-post/edit-post.slice.model';
import { setPostInStore, openExistingOrgTab, closeExistingOrgTab, setFirstTimeOnPage2 } from '../../../../store/slices/edit-post/edit-post.slice';

export type CreatePostingStep02PageProps = {
  history?: any;  
} & any;

const CreatePostingStep02Page = (props: CreatePostingStep02PageProps) => {   
  // useStates
  const [startDate, setStartDate] = useState(new Date());
  const [pressBack, setPressBack] = useState(true);
  const [orgTab, setOrgTab] = useState("create");
  const [organisationsForSelect, setOrganisationsForSelect] = useState<any>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState({selectedOrganisationOption: []});

  // Other hooks initilisation
  const dispatch = useAppDispatch();
  const { editPost, organisations, openExistingOrganisationTab, firstTimeOnPage2 } = useAppSelector(state => state.editPost);
  const { register, handleSubmit, setValue, formState: {errors}} = useForm();
  const profile = useAppSelector(state => state.login.profile);

  let postModelObject:PostModel = {
    id:60,
    authorId: 2,
    title: "",
    description: "",
    img: "",
    link: "",
    keyDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
    isSent: 0,
    published: true,
    about: "",
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    organisation_id: 0,
    organisation: "",
    type: [],
    departments: [],
    identities: [],
    levels: []
  }

  // Event methods
  const onSubmit = (data:any) => {    
   
    postModelObject.title = data.opportunityTitle;
    postModelObject.description = data.description;
    postModelObject.contactName = data.contactName;
    postModelObject.contactEmail = data.contactEmail;
    postModelObject.contactNumber = data.contactNumber;
    postModelObject.organisation = data.organisation;    
    postModelObject.about = data.about;    
    postModelObject.link = data.contactLink;
    postModelObject.keyDate = moment(data.keyDate).format("YYYY-MM-DD hh:mm:ss");

    if(profile.roleId === 1) {
      postModelObject.organisation_id = openExistingOrganisationTab ? (Array.isArray(data.selectedOrganisation) ? data.selectedOrganisation[0].value : data.selectedOrganisation.value) : 0;
    }

    if(profile.roleId === 4) {
      postModelObject.published = false;
    } else {
      postModelObject.published = true;
    }

    postModelObject.departments = editPost.departments;
    postModelObject.identities = editPost.identities;
    postModelObject.levels = editPost.levels;
    postModelObject.type = editPost.type;
    
    dispatch(setPostInStore(postModelObject));

    if(pressBack) {      
      props.history.goBack();
    } else {
      props.history.push(`/create-post/step-3`);
    }
    
  }

  const handleOrganisationChange = (selectedOrganisationOption:any) => {
    console.log(selectedOrganisationOption.value);
    const organisationObj:any = helper.getSelectedOrganisation(organisations, selectedOrganisationOption.value);
    console.log(organisationObj[0]);

    setValue("contactName", organisationObj[0].contactName);
    setValue("contactEmail", organisationObj[0].contactEmail);
    setValue("contactNumber", organisationObj[0].contactNumber);
    setValue("contactLink", organisationObj[0].contactLink);

    setValue("selectedOrganisation", selectedOrganisationOption);
    setSelectedOrganisation({ selectedOrganisationOption });
  }
      
  const setKeyDateHandler = (data:any) => {
    setStartDate(data);
    setValue("keyDate", data);
  };


  let organisationFieldValue = editPost.organisation;
  let titleFieldValue = editPost.title;
  let descriptionFeildValue = editPost.description;
  let contactNameFieldValue = editPost.contactName;
  let contactEmailFieldValue = editPost.contactEmail;  
  let contactNumberFieldValue = editPost.contactNumber;  
  let linkFieldValue = editPost.link; 
  let aboutFieldValue = editPost.about; 

  // useEffect hooks
  useEffect(() => {
    setOrganisationsForSelect(organisations);

    //let selectedOrganisationOption:any = editPost.type;            
    let selectedOrganisationOption:any = helper.getSelectedOrganisation(organisations, editPost.organisation_id);    
    setValue("selectedOrganisation", selectedOrganisationOption);
    setSelectedOrganisation(() => ({selectedOrganisationOption}));

    setStartDate(new Date(editPost.keyDate));
    setValue("keyDate", new Date(editPost.keyDate));
  // eslint-disable-next-line react-hooks/exhaustive-deps              
  },[]);

  useEffect(() => {    
    if(openExistingOrganisationTab) {
      setOrgTab("existing");
    } else {
      setOrgTab("create");
    }

    if(profile.roleId === 4 && firstTimeOnPage2) {
      setValue("contactName", profile.organisation.contactName);
      setValue("contactEmail", profile.organisation.contactEmail);
      setValue("contactNumber", profile.organisation.contactNumber);
      setValue("contactLink", profile.organisation.contactLink);
      dispatch(setFirstTimeOnPage2());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps              
  },[openExistingOrganisationTab]);
  

  return (
    <>
        <div className="heading-container">
          <h2 className="heading-text">CREATE NEW POSTING</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="create-log-in-form">
            <div className="progress-bar-container">
              {window.innerWidth > 400 ? (
                <ProgressBarTwo width="100%" />
              ) : (
                <ProgressBarTwoMobile width="75%" />
              )}
            </div>
            <p className="create-paragraph">
            Share a brief description with students about the posting and select who it applies to.
            </p>

            {profile.roleId === 1 && (
              <>
                  <div className="organisation-buttons">
                    <div
                      onClick={() => dispatch(closeExistingOrgTab())}
                      className={
                        orgTab === "create"
                          ? "organisation-button left selected"
                          : "organisation-button left"
                      }
                    >
                      <p>New Organisation</p>
                    </div>
                    <div
                      onClick={() => dispatch(openExistingOrgTab())}
                      className={
                        orgTab === "existing"
                          ? "organisation-button right selected"
                          : "organisation-button right"
                      }
                    >
                      <p>Existing Organisation</p>
                    </div>
                  </div>

                  {orgTab !== "existing" && (
                      <label>
                          Organisation Name
                          <input
                            {...register("organisation", {required: true, minLength:2 })}
                            type="text"
                            defaultValue={organisationFieldValue}
                          />
                          {errors.organisation && (
                            <p className="error-message">
                              Enter the name of the organisation that this posting relates
                              to
                              <Error />
                            </p>
                          )}
                      </label>
                  )}

              </>              
            )}

            {orgTab === "existing" && profile.roleId === 1 && (
                <label>
                  Organisation Name
                  <Select
                    {...register("selectedOrganisation", {required: true })}
                    styles={customStyles}                            
                    options={organisationsForSelect}
                    onChange={handleOrganisationChange}
                    value={selectedOrganisation.selectedOrganisationOption}
                    placeholder="Select an organisation"
                    theme={theme => helper.selectedTheme(theme)}
                  />
                  {errors.selectedOrganisation && <p className="error-message">Oganisation name is required<Error /></p>}
                </label>            
            )}
                
            <label>
              Opportunity title
              <input
                {...register("opportunityTitle", {required: true, minLength: 3 })}     
                defaultValue={titleFieldValue}           
              />
              {errors.opportunityTitle && (
                <p className="error-message">
                  Create an opportunity title
                  <Error />
                </p>
              )}              
            </label>
            <label>
              Description of the posting
              <textarea
                {...register("description", {required: true, minLength:3 })}
                defaultValue={descriptionFeildValue}
              />
              {errors.description && (
                  <p className="error-message">
                    Add description of posting
                    <Error />
                  </p>
              )}
            </label>
            <label>
              Event date and time
              <DatePicker
                {...register("keyDate")}
                selected={startDate}
                onChange={date => setKeyDateHandler(date)}
                showTimeSelect
                timeCaption="Time"
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy h:mm aa"
                name="date"
              />
              {errors.keyDate && (
                <p className="error-message">
                  Select date and time
                  <Error />
                </p>
              )}     
            </label>

            {orgTab !== "existing" && profile.roleId === 1 && (
                  <label>
                  About the organisation
                  <textarea
                    {...register("about", {required: true, minLength:3 })}
                    defaultValue={aboutFieldValue}
                  />
                  {errors.about && (
                      <p className="error-message">
                        Add details about the organisation
                        <Error />
                      </p>
                  )}
                </label>  
            )}          

            <label>
              Contact name
              <input
                {...register("contactName", {required: true, min: 3 })}
                defaultValue={contactNameFieldValue}
              />
              {errors.contactName && (
                <p className="error-message">
                  Add a contact persons name
                  <Error />
                </p>
              )}              
            </label>

            <label>
              Contact email
              <input
                {...register("contactEmail", {
                    required: true, 
                    min: 3,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                })}
                defaultValue={contactEmailFieldValue}
              />
              {errors.contactEmail && (
                <p className="error-message">
                  Add a contact persons email
                  <Error />
                </p>
              )}
            </label>
            <label>
              Contact number
              <input
                {...register("contactNumber", {required: true, min: 3 })}
                defaultValue={contactNumberFieldValue}
              />
             {errors.contactNumber && (
                <p className="error-message">
                  Add a contact persons number
                  <Error />
                </p>
              )}
            </label>
            <label>
              Link
              <input
                {...register("contactLink", {
                    required: true, 
                    min: 3,
                    pattern: /^(https:|http:|www\.)\S*/i
                })}
                defaultValue={linkFieldValue} 
              />
             {errors.contactLink && (
                <p className="error-message">
                    Invalid URL pattern (e.i https://www.example.com)
                  <Error />
                </p>
              )}              
            </label>                
            <div className="button-container">
                <CustomButton 
                    butonStyle="navigation" 
                    onClick={() => setPressBack(true)} 
                    iconLeft={true} 
                    icon={Backward}
                    type="submit"
                  >
                  BACK
                </CustomButton>   
                <CustomButton 
                    butonStyle="navigation" 
                    onClick={() => setPressBack(false)} 
                    iconRight={true} 
                    icon={Forward}
                    type="submit"
                  >
                  NEXT
                </CustomButton>                           
            </div>                
        </form>
    </>
  );
}

export default CreatePostingStep02Page;