import { useEffect, useState } from 'react';

// Imported assets
import Delete from "../../../../assets/close_.png";
import Back from "../../../../assets/back.png";
import Edit from "../../../../assets/edit.png";
import Publish from "../../../../assets/publish_.png";

// Style related imports
import "./post-detail.style.scss";

// Service and redux imports
import ApiService from '../../../../services/api/api.service';
import {useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {show, hide} from '../../../../store/slices/loading/loading.slice';
import { setFetchFromServer } from '../../../../store/slices/edit-post/edit-post.slice';


// Custom components and helper imports
import CustomButton from '../../../components/custom-button/custom-button.component';
import CustomWidget from '../../../components/custom-widget/custom-widget.component';
import PostDetailComponent, { PostDetailComponentProps } from './post-detail.component';
import helper from './post-detail.helpers';
import CustomPrompt from '../../../components/prompts/custom-prompt.component';
import { baseURL } from '../../../../services/helper/axios.helper';

export type PostDetailPageProps = {
  history?: any;  
} & any;

const PostDetailPage = (props: PostDetailPageProps) => { 
  
  const [departmentItems, setDepartmentItems] = useState<any>([]);
  const [levelItems, setLevelItems] = useState<any>([]);
  const [identityItems, setIdentityItems] = useState<any>([]);
  const [typeItem, setTypeItem] = useState<any>([]);
  const [publishInfo, setPublishInfo] = useState<any>([]);
  const [peopleInterestedNum, setPeopleInterestedNum] = useState<any>([]);
  const [viewNum, setViewNum] = useState<any>([]);  
  const [postDetails, setPostDetails] = useState<PostDetailComponentProps>({isPublished: false});
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [unpublishConfirmation, setUnpublishConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  
  const postId = props.match.params.id;
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.login.profile);

  const postDetailObject:PostDetailComponentProps = {};

  const deletePost = () => {
    setDeleteConfirmation(false)
    dispatch(show());
    ApiService.deletePostById(postId)
        .then(() => {
            dispatch(hide());
            props.history.push(`/dashboard-admin`);
        })
        .catch(() => {

        })
  }

  const publishPost = () => {    
    setPublishConfirmation(false);
    dispatch(show());
    ApiService.getPublishPostById(postId)
    .then((data) => {
        dispatch(hide());
        props.history.push(`/postings/published`);
    })
    .catch((error) => {

    })
  }

  const unPublishPost = () => {
    setUnpublishConfirmation(false);
    dispatch(show());
    ApiService.getUnPublishPostById(postId)
    .then((data) => {
        dispatch(hide());
        props.history.push(`/postings/pending`);
    })
    .catch((error) => {

    })
  }

  const gotoEditPostStep1 = () => {
    dispatch(setFetchFromServer());
    props.history.push(`/edit-post/step-1/${postId}`);
  }

  useEffect(() => {

        dispatch(show());
        ApiService.getPostById(postId)
            .then((post:any) => {

                postDetailObject.contactEmail = post.data.contactEmail;
                postDetailObject.contactName = post.data.contactName;
                postDetailObject.postTitle = post.data.title;
                postDetailObject.icon = baseURL + "/storage/types/June2020/BJixYcGyrDIlVKENehYw.png";
                postDetailObject.organisation = post.data.organisation;
                postDetailObject.description = post.data.description;
                postDetailObject.datePosted = post.data.keyDate;
                postDetailObject.contactEmail = post.data.contactEmail;
                postDetailObject.contactNumber = post.data.contactNumber;
                postDetailObject.contactLink = post.data.link;
                postDetailObject.isPublished = post.data.published;

                if(profile.userId  === post.data.authorId) {
                  setCanEdit(true);
                }

                if(profile.roleId === 6 && (post.data.organisation_id  === profile.organisation.id)) {
                  setCanEdit(true);
                }

                if(profile.roleId === 1) {
                  setCanEdit(true);
                }

                setPostDetails(postDetailObject);

                setDepartmentItems(() => helper.generateCustomArray(post.data.departments));
                setLevelItems(() => helper.generateCustomArray(post.data.levels));
                setIdentityItems(helper.generateCustomArray(post.data.identities));
                setTypeItem([{
                  name: post.data.type.name
                }]);
                setPublishInfo([{
                  name: post.published ? "Yes" : "No"
                }]);
  
                ApiService.getPostViewCountByPostId(postId)
                    .then((postCount:any) => {
                      
                      if(postCount.data > 0) {
                        setViewNum([{
                          name: postCount.data
                        }])
                      } else {
                        setViewNum([{
                          name: "None right now, but check back soon!"
                        }])
                      }

                      ApiService.getFavorites()
                          .then((favoriteCount:any) => {                                                       
                            if(favoriteCount.data > 0) {
                              setPeopleInterestedNum([{
                                name: favoriteCount.data
                              }])
                            } else {
                              setPeopleInterestedNum([{
                                name: "None right now, but check back soon!"
                              }])
                            }
                            dispatch(hide());
                          })
                          .catch(() => {
                            dispatch(hide());
                          });                      
                      
                    })
                    .catch(() => {
                      dispatch(hide());
                    });                
            })
            .catch(() => {
              dispatch(hide());              
            })

      // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dispatch]);

  return (
    <>    
      {deleteConfirmation && (
        <CustomPrompt
          onClick={() => deletePost()}
          closeHandler={() => setDeleteConfirmation(false)}
          confirmLabelButton="DELETE POST"
          cancelLabelButton="NO, BACK TO POST"
          isWarning={true}
          promptMessage="ARE YOU SURE YOU WOULD LIKE TO DELETE THIS POST?"
        />
      )}

      {publishConfirmation && (
        <CustomPrompt
          onClick={() => publishPost()}
          closeHandler={() => setPublishConfirmation(false)}
          confirmLabelButton="CONTINUE"
          cancelLabelButton="NO, BACK TO POST"
          isWarning={false}
          promptMessage="CONTINUE TO MAKE IT VISIBLE TO STUDENTS."
        />
      )}

      {unpublishConfirmation && (
        <CustomPrompt
          onClick={() => unPublishPost()}
          closeHandler={() => setUnpublishConfirmation(false)}
          confirmLabelButton="CONTINUE"
          cancelLabelButton="NO, BACK TO POST"
          isWarning={false}
          promptMessage="POST WILL NO LONGER BE VISIBLE TO STUDENTS"
        />
      )}

      <div className="heading-container">
        <h2 className="heading-text">VIEW POST</h2>
      </div>
      <div className="view-individual-post-container">

        {!canEdit &&  (
            <div className="button-container notification-text">
              Read only. You can only edit and delete your own post.
            </div>
        )}

        <div className="button-container">

          {postDetails.isPublished && (
              <CustomButton 
                butonStyle="navigation" 
                onClick={() => props.history.goBack()}
                iconLeft={true}
                icon={Back}
              >
              BACK
              </CustomButton>
          )}

          {!postDetails.isPublished && (
              <CustomButton 
                butonStyle="navigation" 
                onClick={() => props.history.push('/postings/pending')} 
                iconLeft={true}
                icon={Back}
              >
              BACK
              </CustomButton>
          )}

          { canEdit && (
            <CustomButton 
                butonStyle="action" 
                onClick={() => gotoEditPostStep1()} 
                iconLeft={true}
                icon={Edit}
              >
              EDIT
            </CustomButton>              
          )}              
          </div>

          <div className="preview-form-container">
              <PostDetailComponent {...postDetails}/>
          </div>

          <CustomWidget title="Student Group/s" items={departmentItems} />
          <CustomWidget title="What Level Student/s" items={levelItems} />
          <CustomWidget title="Identities" items={identityItems} />
          <CustomWidget title="Type" items={typeItem} />
          <CustomWidget title="Published" items={publishInfo} />
          <CustomWidget title="People Interested" items={peopleInterestedNum} />
          <CustomWidget title="Views" items={viewNum} />          

          <div className="button-container">         
            
            { canEdit && (
              <CustomButton 
                  butonStyle="warning" 
                  onClick={() => setDeleteConfirmation(true)} 
                  iconRight={true} 
                  icon={Delete}
                >
                DELETE
              </CustomButton>
            )}

            {profile.roleId !== 4 && postDetails.isPublished && canEdit && (
                <CustomButton 
                    butonStyle="action" 
                    onClick={() => setUnpublishConfirmation(true)} 
                    iconRight={true} 
                    icon={Publish}
                  >
                  UNPUBLISH
                </CustomButton>            
            )}

            {profile.roleId !== 4 && !postDetails.isPublished && canEdit && (
                <CustomButton 
                    butonStyle="action" 
                    onClick={() => setPublishConfirmation(true)} 
                    iconRight={true} 
                    icon={Publish}
                  >
                  PUBLISH
                </CustomButton>            
            )}      
            
          </div>
      </div>
    </>
  );
}

export default PostDetailPage;