import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostSliceModel } from './post.slice.model'
import { AppInitialState } from '../../app.initial.state';


const initialState: PostSliceModel = AppInitialState.post;

export const PostSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    fetchPost: (state) => {
      state.isFetchingPost = true;
    },
    fetchPostSuccess: (state, action:PayloadAction<any[]>) => {
      state.error = null;
      state.isFetchingPost = false;
      state.posts = action.payload;      
    },
    fetchPostFail: (state,  action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isFetchingPost = false;      
    },
    fetchTypeSuccess: (state, action:PayloadAction<any[]>) => {
      state.postTypes = action.payload;
    }
  }
});

export const {fetchPost, fetchPostSuccess, fetchPostFail, fetchTypeSuccess} = PostSlice.actions;

export default PostSlice.reducer;