import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ReactComponent as Kaore } from "../../../assets/404.svg";
import { ReactComponent as KaoreSmall } from "../../../assets/404-small.svg";
import { ReactComponent as KaoreMedium } from "../../../assets/404-medium.svg";

import "./not-found.style.scss";

const NotFoundPage = () => { 
  const [redirect, setRedirect] = useState(false);
  
  useEffect(() => {
    const ac = new AbortController();
    let handle = setTimeout(() => {
      setRedirect(true);
    }, 3000);

    return function cleanup() {
      clearTimeout(handle);
      ac.abort();
    }
  }, []);

  const kaoreImage = () => {
    const width = window.innerWidth;
    if (width >= 500 && width <= 699) return <KaoreMedium />;
    else if (width > 700) return <Kaore />;
    else return <KaoreSmall width="auto" />;
  };

  return (
    <div className="not-found">
      {redirect ? (
        <Redirect to={"/"} />
      ) : (
        <div className="kaore-image">{kaoreImage()}</div>
      )}
    </div>
  );
}

export default NotFoundPage;