import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../../assets/log-in-logo.svg";

import './about.page.style.scss';


export type AboutPageProps = {
  history?: any;  
} & any;

const AboutPage = (props: AboutPageProps) => { 

    return (
        <>
          <div className="heading-container">
              <h2 className="heading-text">ABOUT</h2>
          </div>
          <div className="about-container">
                <div className="about-logo">
                    <Logo />
                </div>
                <p className="about-page-text">
                    Pūngao Torohū enables students (and future students) to engage and have an ongoing relationship with technology pertinent to their journey through academia at the university of Auckland.
                </p>
                <p className="about-page-text">
                    Pūngao Torohū has been created to connect tauira (students) to people and opportunity. As a connection platform, Pūngao Torohū links opportunities direct to device.
                </p>
                <p className="about-page-text">
                    Users receive posts aligned to their profile information ‘channel.
                    Stakeholders are be able to post opportunities direct to their audience and channels are able to correspond to each other.
                    It is compulsory that any communications from Pūngao Torohū to tauira be purposeful, linked to opportunity and incentivised where possible.
                </p>
                <p className="about-page-text">
                    If you wish to find out more, please get in touch via the <Link to="/help/contact"><span className="about-link">Contact Page</span></Link>.
                </p>
            </div>
        </>
    )
    
}

export default AboutPage;