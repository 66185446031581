import React from "react";
import "./footer.component.style.scss";
import { ReactComponent as AucklandLogo } from "../../../assets/auckland-university-footer.svg";
import { withRouter } from "react-router";

const Footer = () => {
  return (
    <div className="footer-container">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.auckland.ac.nz/en.html"
      >
        <AucklandLogo />
      </a>
    </div>
  );
}

export default withRouter(Footer);
